import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { useAppDispatch } from '../../state-manager/store';
import {
  getCurrentPatientIdSelector,
  getRequestStatusSelector,
  getUserIdSelector,
  getUserRoleSelector,
} from '../../state-manager/selectors/appSelectors';
import { fulfillUserAction } from '../../state-manager/thunks/usersThunks';
import {
  fetchAssignTreatmentPlansAction,
  fetchTreatmentPlanTemplatesAction,
  fulfillAllActivitiesResultsAction,
} from '../../state-manager/thunks/treatmentPlansThunks';
import { isFailed } from '../../state-manager/utils/requestStatus';
import { notify } from '../../state-manager/slices/notificationsSlice';
import { fulfillActivityDefinitionsAction } from '../../state-manager/thunks/libraryThunks';
import { createSelector } from '@reduxjs/toolkit';
import { getPatientActivitiesExecutionsSelector } from '../../models/ActivityExecution';
import { usePulling } from '../commons/hooks/usePulling';
import {
  getCurrentPatientSelector,
  getUsersSelector,
} from '../../models/factories/userFactories';
import { getPatientTreatmentPlansSelector } from '../../models/factories/treatmentPlanFactories';
import { setCurrentPatientIdAction } from '../../state-manager/slices/appSlice';
import { fetchMovementsResults } from '../../services/treatmentPlanService';
import { setMovementsResultsAction } from '../../state-manager/slices/treatmentPlansSlice';
import { getPatientCaseDtosSelector } from '../../state-manager/selectors/casesSelectors';
import {
  changeSelectedCaseId,
  createCaseAction,
  fetchCasesAction,
} from '../../state-manager/thunks/casesThunks';
import { urlParamsVulnerable } from '../../utils/urlSearchParamsUtils';

export function useUpdateCurrentPatientId() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const role = useSelector(getUserRoleSelector);
  const mainUserId = useSelector(getUserIdSelector);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    let patientId: string | null | undefined;
    if (!role) {
      return;
    }
    if (role === 'patient') {
      patientId = mainUserId;
      if (!patientId) {
        dispatch(
          notify({
            message: 'somethings wrong',
          }),
        );
      }
    } else {
      patientId = searchParams.get('patient-id');
      // https://jira.devtools.intel.com/browse/CAREAI-9222
      // [CAREAI-9222] Path Traversal Postulate in Clinician Portal
      if (!patientId || urlParamsVulnerable(patientId)) {
        dispatch(
          notify({
            message: 'the link must contain a valid patient-id',
          }),
        );
        setTimeout(() => {
          history.push('/patients');
        }, 3000);
        return;
      }
    }
    dispatch(setCurrentPatientIdAction({ patientId }));
  }, [location, role]);
}

export function useUpdateStoreCaseIdFromUrl() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const patientId = useSelector(getCurrentPatientIdSelector);
  const patientCases = useSelector(getPatientCaseDtosSelector(patientId));

  useEffect(() => {
    const caseId = new URLSearchParams(history.location.search).get('case-id');
    if (patientCases !== undefined && caseId !== null) {
      if (!urlParamsVulnerable(caseId)) {
        dispatch(changeSelectedCaseId(caseId));
      } else {
        dispatch(
          notify({
            message: 'case-id contains illegal vulnerable characters.',
          }),
        );
      }
    }
  }, [location, patientCases]);
}

export function useAddMandatoryCase() {
  const dispatch = useAppDispatch();

  const patient = useSelector(getCurrentPatientSelector);

  const caseDtos = useSelector(getPatientCaseDtosSelector(patient?.id));

  useEffect(() => {
    if (caseDtos?.length === 0 && patient) {
      if (patient.emrUserId) {
        throw new Error('this is emr patient and he expect to have caseId');
      }
      dispatch(createCaseAction(patient.id, 'NA-portal'));
    }
  }, [caseDtos, patient]);
}

export function useOnCasesChangeUpdateUrl() {
  const location = useLocation();
  const history = useHistory();
  const patientId = useSelector(getCurrentPatientIdSelector);
  const patientCases = useSelector(getPatientCaseDtosSelector(patientId));

  useEffect(() => {
    if (!history.location.pathname.includes('/patient/')) {
      return;
    }
    const searchParams = new URLSearchParams(history.location.search);
    if (patientCases && patientCases.length > 0) {
      const currentCaseIdInUrl = searchParams.get('case-id');
      if (currentCaseIdInUrl !== null) {
        return;
      }
      const defaultCase = patientCases[0];
      if (!defaultCase) {
        return;
      }

      searchParams.set('case-id', defaultCase.case_id);

      history.push({
        pathname: history.location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [patientCases, location]);
}

export function useFulfillPatientData() {
  const dispatch = useAppDispatch();
  const patientId = useSelector(getCurrentPatientIdSelector);
  const users = useSelector(getUsersSelector);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    if (!users[patientId]) {
      dispatch(fulfillUserAction(patientId));
    }
  }, [patientId, users]);
}

export function useFulfillPatientTreatmentPlans() {
  const dispatch = useAppDispatch();
  const treatmentPlans = useSelector(getPatientTreatmentPlansSelector);
  const patient = useSelector(getCurrentPatientSelector);

  useEffect(() => {
    if (!treatmentPlans && patient) {
      dispatch(
        fetchAssignTreatmentPlansAction({
          patientId: patient.id,
        }),
      );
    }
  }, [treatmentPlans, patient]);
}

export function useFulfillPatientCases() {
  const dispatch = useAppDispatch();
  const patientId = useSelector(getCurrentPatientIdSelector);
  const caseDtos = useSelector(getPatientCaseDtosSelector(patientId));

  useEffect(() => {
    if (!caseDtos && patientId) {
      dispatch(fetchCasesAction(patientId));
    }
  }, [caseDtos, patientId]);
}

export function useHandleFulfillPatientFailed() {
  const dispatch = useAppDispatch();
  const patientId = useSelector(getCurrentPatientIdSelector);
  const requestStatus = useSelector(getRequestStatusSelector('user'));
  const history = useHistory();

  useEffect(() => {
    if (!patientId) {
      return;
    }
    if (isFailed({ requestStatus, id: patientId })) {
      dispatch(
        notify({
          message: `We have problem with this patient: ${patientId}. Try to find him again`,
          severity: 'error',
          origin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }),
      );
      setTimeout(() => {
        history.push('/patients');
      }, 6000);
    }
  }, [requestStatus, patientId]);
}

export function useHandleFulfillTreatmentPlansFailed() {
  const dispatch = useAppDispatch();
  const requestStatus = useSelector(getRequestStatusSelector('fetchAssignTreatmentPlans'));
  const history = useHistory();

  const patient = useSelector(getCurrentPatientSelector);
  useEffect(() => {
    if (!patient) {
      return;
    }
    // eslint-disable-next-line max-len
    const message = `We have problem with Patient: ${patient.name} details, please try again later or contact Customer Service`;
    if (isFailed({ requestStatus, id: patient.id })) {
      dispatch(
        notify({
          message,
          severity: 'error',
        }),
      );
      setTimeout(() => {
        history.push('/patients');
      }, 4000);
    }
  }, [{ requestStatus, patient }]);
}

export function useFulfillAllPatientActivitiesData() {
  const dispatch = useAppDispatch();
  const patientId = useSelector(getCurrentPatientIdSelector);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    dispatch(fulfillAllActivitiesResultsAction(patientId));
  }, [patientId]);
}

export function useFulfillActivityDefinitionsAction() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fulfillActivityDefinitionsAction());
  }, []);
}

export function useFetchTreatmentPlanTemplates() {
  const role = useSelector(getUserRoleSelector);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!role || role === 'patient') {
      return;
    }
    dispatch(fetchTreatmentPlanTemplatesAction());
  }, [role]);
}

const getLatestActivityExecutionSelector = createSelector(
  [getPatientActivitiesExecutionsSelector],
  (patientActivitiesExecutions) => {
    if (patientActivitiesExecutions) {
      patientActivitiesExecutions.sort((a, b) => {
        return a.stopDate.getTime() - b.stopDate.getTime();
      });
      return patientActivitiesExecutions[patientActivitiesExecutions.length - 1];
    }
  },
);

export function usePullingPatientResults() {
  const dispatch = useAppDispatch();
  const patientId = useSelector(getCurrentPatientIdSelector);
  const latestActivityExecution = useSelector(getLatestActivityExecutionSelector);
  const stopPulling = useRef<(() => void) | undefined>();
  const defaultStartDate = useMemo(() => {
    return new Date();
  }, []);

  const pullingRoutine = useCallback(() => {
    if (!patientId) {
      return Promise.resolve();
    }
    const startDate = latestActivityExecution?.startDate ?? defaultStartDate;

    return fetchMovementsResults({
      userId: patientId,
      startDate,
    })
      .then((movementResultsOutputDto) => {
        if (movementResultsOutputDto) {
          dispatch(
            setMovementsResultsAction({
              patientId,
              movementsResults: movementResultsOutputDto.movements,
            }),
          );
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [patientId, latestActivityExecution]);

  stopPulling.current = usePulling({
    routine: pullingRoutine,
  });

  useEffect(() => {
    return () => {
      stopPulling.current?.();
    };
  }, []);
}
