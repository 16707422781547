/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LogoIcon from './../../assets/logo.svg';
import SupportIcon from './../../assets/support.svg';
import { colors } from '../../style/colors';
import UserMenu from './UserMenu';
import { fonts } from '../../style/fonts';
import SettingsMenu from './SettingsMenu';
import SupportModal from '../support/SupportModal';
import { getUserRoleSelector } from '../../state-manager/selectors/appSelectors';
import { useSelector } from 'react-redux';
export const MAIN_HEADER_HEIGHT = '60px';

const LOGO_WIDTH = 113;

const supportButtonCss: CSSObject = {
  width: '30px',
  height: '30px',
  '&:hover': {
    cursor: 'pointer',
  },

  '&:active': {
    cursor: 'default',
  },
};

const blueAreaCss: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: `calc(100% - ${LOGO_WIDTH}px)`,
  height: '100%',
  background:
    'linear-gradient(90deg, #025CBD 0%, #0155AF 38.6%, #0052AB 72.06%, #012664 99.99%)',
  paddingLeft: 10,
  paddingRight: 30,
  '.tabs-container > button': {
    fontSize: fonts.navTab?.fontSize,
    color: '#B8EEFF',
    '&:hover, &.selected': {
      textDecorationThickness: '4px',
      textUnderlineOffset: '10px',
      color: colors.white,
    },
  },
};

export default function MainHeader() {
  const [supportModalIsOpen, setSupportModalIsOpen] = useState<boolean>(false);
  const history = useHistory();

  const role = useSelector(getUserRoleSelector);

  return (
    <>
      <div
        css={{
          position: 'relative',
          cursor: 'pointer',
          display: 'flex',
          height: MAIN_HEADER_HEIGHT,
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: LOGO_WIDTH,
            height: '100%',
            backgroundColor: colors.white,
            borderRight: '6px solid',
            borderImage:
              'linear-gradient(to right, #6CC4F5 100%, #0099EC 100%) 1',
          }}
        >
          <LogoIcon
            width="83"
            height="27"
            onClick={() => {
              if (role === 'patient') {
                return;
              }
              history.push('/patients');
            }}
          />
        </div>
        <div css={blueAreaCss}>
          <span css={{ ...fonts.bannerFont, color: colors.white }}>
            Intel RealSense PT Metrics
          </span>
          <div css={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            <SupportIcon
              css={supportButtonCss}
              onClick={() => setSupportModalIsOpen(true)}
            />
            <SettingsMenu />
            <UserMenu />
          </div>
        </div>
      </div>
      <SupportModal
        isOpen={supportModalIsOpen}
        setModalMode={setSupportModalIsOpen}
      />
    </>
  );
}
