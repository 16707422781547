/** @jsxImportSource @emotion/react */

import { ChangeEvent, useState, useEffect, useMemo } from 'react';
import { CSSObject } from '@emotion/react';
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSizingState,
  Header,
  PaginationState,
  SortingState,
  Table,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { colors } from '../../style/colors';
import { ellipsis } from '../../utils/cssUtils';
import { buttons } from '../../style/buttons';

const SortingIconCssObject: CSSObject = {
  height: '14px',
  color: colors.basicBlue,
};

const tableViewCssObject: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: '100%',
  height: '100%',
  overflow: 'auto',

  '.resizer': {
    position: 'absolute',
    right: -5,
    top: 0,
    height: '100%',
    width: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'col-resize',
    userSelect: 'none',
    touchAction: 'none',
  },
  '.resizer.isResizing': {
    opacity: 1,
  },
  '.sorting': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    cursor: 'pointer',
  },
  '.table': {
    width: 'fit-content',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: colors.white,
  },

  '.table-header': {
    display: 'flex',
  },

  '.table-header-cell, .table-body-cell': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgb(224, 224, 224)',
    padding: 3,
  },

  '.table-body': {
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    flexGrow: 1,
    overflowY: 'scroll',
  },

  '.table-body-row': {
    display: 'flex',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.selected-row': {
      backgroundColor: colors.dividerGrey,
    },
  },

  '.table-body-cell': {
    borderLeft: '1px solid rgb(224, 224, 224)',
    padding: 20,
  },

  '.table-header-cell': {
    fontWeight: 500,
    borderLeft: '1px solid rgb(224, 224, 224)',
    padding: 20,
  },

  '.pagination-view': {
    display: 'flex',
    gap: 20,
  },
};

interface DataType extends Object {}

function isTableOnResizing<T extends DataType>(table: Table<T>) {
  const tableOnResizing = table
    .getAllColumns()
    .reduce((accumulator, currentColumn) => {
      return accumulator || currentColumn.getIsResizing();
    }, false);
  return tableOnResizing;
}

function TableBody<T extends DataType>({
  table,
  onRowClick,
  selectedRowDateId,
  getRowId,
}: {
  table: Table<T>;
  onRowClick: (rowData: T) => void;
  selectedRowDateId?: string;
  getRowId: (rowData: T) => string;
}) {
  return (
    <div className="table-body">
      {table.getRowModel().rows.map((row) => {
        const id = getRowId(row.original);
        let className = 'table-body-row';
        if (id === selectedRowDateId) {
          className = className + ' selected-row';
        }

        return (
          <div
            className={className}
            key={id}
            onClick={() => {
              onRowClick(row.original);
            }}
          >
            {row.getVisibleCells().map((cell) => (
              <div
                className="table-body-cell"
                key={`${id}_${cell.id}`}
                css={{ width: cell.column.getSize() }}
              >
                <div css={{ ...ellipsis, width: '100%' }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

function ResizingView<T extends DataType>({
  header,
}: {
  header: Header<T, unknown>;
}) {
  if (!header.column.getCanResize()) {
    return <></>;
  }
  return (
    <div
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
    />
  );
}

function SortingView<T extends DataType>({
  header,
}: {
  header: Header<T, unknown>;
}) {
  if (!header.column.getCanSort()) {
    return <></>;
  }
  let sortDirection = header.column.getIsSorted();
  if (!sortDirection) {
    sortDirection = 'asc';
  }

  return (
    <div className="sorting">
      {
        {
          asc: <SouthIcon css={SortingIconCssObject} />,
          desc: <NorthIcon css={SortingIconCssObject} />,
        }[sortDirection]
      }
    </div>
  );
}

function FilterView<T extends DataType>({
  header,
}: {
  header: Header<T, unknown>;
}) {
  if (!header.column.getCanFilter()) {
    return <></>;
  }
  const columnFilterValue = header.column.getFilterValue();
  return (
    <input
      type="text"
      value={(columnFilterValue ?? '') as string}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        header.column.setFilterValue(event.target.value);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      placeholder="Filter.."
    />
  );
}

function TableHeaderCell<T extends DataType>({
  header,
  table,
}: {
  header: Header<T, unknown>;
  table: Table<T>;
}) {
  const [hover, setHover] = useState<boolean>(false);

  const tableOnResizing = isTableOnResizing(table);

  const showResizer =
    (hover && !tableOnResizing) || header.column.getIsResizing();

  return (
    <div
      className="table-header-cell"
      css={{ width: header.getSize(), flexDirection: 'column' }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={header.column.getToggleSortingHandler()}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <div css={{ ...ellipsis, width: '100%' }}>
          {flexRender(header.column.columnDef.header, header.getContext())}
        </div>
        {<SortingView header={header} />}
        {showResizer && <ResizingView header={header} />}
      </div>
      {<FilterView header={header} />}
    </div>
  );
}

function TableHead<T extends DataType>({ table }: { table: Table<T> }) {
  const headerGroup = table.getHeaderGroups()[0];
  if (!headerGroup) {
    return <></>;
  }
  return (
    <div className="table-header">
      {headerGroup.headers.map((header) => (
        <TableHeaderCell key={header.id} header={header} table={table} />
      ))}
    </div>
  );
}

function getStoredColumnSizing(tableId: string) {
  const columnSizingStr = localStorage.getItem(tableId);
  const columnSizing: ColumnSizingState = columnSizingStr
    ? JSON.parse(columnSizingStr)
    : {};
  return columnSizing;
}

function PaginationView<T extends DataType>({ table }: { table: Table<T> }) {
  return (
    <div className="pagination-view">
      <button
        css={{ ...buttons.secondarySmall }}
        onClick={() => table.firstPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </button>
      <button
        css={{ ...buttons.secondarySmall }}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </button>
      <button
        css={{ ...buttons.secondarySmall }}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>'}
      </button>
      <button
        css={{ ...buttons.secondarySmall }}
        onClick={() => table.lastPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </button>
      {/* <select
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select> */}
    </div>
  );
}

export default function TableView<T extends DataType>({
  tableId,
  onRowClick,
  globalFilter,
  setGlobalFilter,
  data,
  columns,
  selectedRowDateId,
  getRowId: externalGetRowId,
  initSorting,
}: {
  tableId: string;
  onRowClick: (rowData: T) => void;
  globalFilter?: string;
  setGlobalFilter?: (str: string) => void;
  data: T[];
  columns: ColumnDef<T, any>[];
  selectedRowDateId?: string;
  getRowId?: (rowData: T) => string;
  initSorting?: SortingState;
}) {
  const [sorting, setSorting] = useState<SortingState>(initSorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const initColumnSizing = useMemo(() => {
    return getStoredColumnSizing(tableId);
  }, []);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const getRowId = (rowData: T): string => {
    let id: string | undefined = undefined;
    if (externalGetRowId) {
      id = externalGetRowId(rowData);
    } else if ('id' in rowData && typeof rowData.id === 'string') {
      id = rowData.id;
    }
    if (!id) {
      throw new Error('id is missing - pls supply getRowId function');
    }
    return id;
  };

  const customGlobalFilterFn = (
    row: any,
    columnId: string,
    filterValue: string,
  ) => {
    const searchTerms = filterValue.toLowerCase().split(' ');

    const cellValue = row.getValue(columnId).toLowerCase();
    return searchTerms.every((term: string) => cellValue.includes(term));
  };

  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    state: {
      globalFilter: globalFilter,
      sorting: sorting,
      columnFilters,
      pagination,
    },
    globalFilterFn: customGlobalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      columnSizing: initColumnSizing,
    },
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  useEffect(() => {
    if (!isTableOnResizing(table)) {
      const columnSizingStr: string = JSON.stringify(
        table.getState().columnSizing,
      );
      if (columnSizingStr === '{}') {
        return;
      }
      localStorage.setItem(tableId, columnSizingStr);
    }
  }, [table.getState().columnSizing]);

  return (
    <div css={tableViewCssObject}>
      <div className="table">
        <TableHead table={table} />
        <TableBody
          table={table}
          onRowClick={onRowClick}
          selectedRowDateId={selectedRowDateId}
          getRowId={getRowId}
        />
      </div>
      <PaginationView table={table} />
    </div>
  );
}
