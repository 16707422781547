/** @jsxImportSource @emotion/react */

import { useSelector } from 'react-redux';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

import { card, shapes } from '../../../../style/shapes';
import { fonts } from '../../../../style/fonts';
import { colors } from '../../../../style/colors';
import ViewEyeIcon from '../../../../assets/viewEye.svg';
import AddCircleIcon from '../../../../assets/addCircle.svg';
import TrashIcon from '../../../../assets/trash.svg';
import Carousel from '../../../commons/Carousel';
import { Activity } from '../../../../models/Activity';
import { CSSObject } from '@emotion/react';
import { getRequestStatusSelector } from '../../../../state-manager/selectors/appSelectors';
import { isFinished } from '../../../../state-manager/utils/requestStatus';
import Loader from '../../../commons/Loader';
import { useAppDispatch } from '../../../../state-manager/store';
import { deleteTreatmentPlanTemplateAction } from '../../../../state-manager/thunks/treatmentPlansThunks';
import { getTreatmentPlanTemplatesSelector } from '../../../../models/factories/treatmentPlanTemplateFactories';
import ActivityThumbnail from '../../../commons/ActivityThumbnail';
import { TreatmentPlanTemplate } from '../../../../models/TreatmentPlanTemplate';
import { SearchTextField } from '../../../commons/SearchTextField';
import TreatmentPlanPreview from '../commons/TreatmentPlanPreview';
import { buttons } from '../../../../style/buttons';
import { AskUser } from '../../../commons/AskUser';
import { messages } from '../../../../messages';
import { setEditTemplateIdAction } from '../../../../state-manager/slices/appSlice';
import { breakpoints } from '../../../../style/breakpoints';
import EditIcon from '../../../../assets/edit.svg';

const templateSelectionCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: 20,
  paddingTop: '20px',
};

const templateListCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '1.25rem',
  overflowY: 'auto',
  flex: 1,

  [breakpoints.small]: {
    padding: '0 9px',
  },

  [breakpoints.medium]: {
    padding: '0 13px',
  },

  [breakpoints.large]: {
    padding: '0 22px',
  },
};

const templateContainer: CSSObject = {
  padding: 10,
  overflow: 'visible',

  '.header': {
    display: 'flex',
    justifyContent: 'space-between',

    h2: {
      fontFamily: fonts.h2?.fontFamily,
      fontSize: '20px',
      fontWeight: 500,
      margin: 0,
    },
  },
};

const actionsAreaCss: CSSObject = {
  display: 'flex',
  gap: 13,
};

const activityCarouselItemCss: CSSObject = {
  width: 131,
  overflow: 'hidden',
  marginRight: '1.25rem',
  '&:last-of-type': { marginRight: 0 },

  '.name': {
    ...fonts.smallLabel,
    whiteSpace: 'nowrap',
  },
};

const templateViewModalCss: CSSObject = {
  '.MuiDialog-paper': {
    width: '80%',
    maxWidth: '1583px',
    height: '719px',
  },

  '.header': {
    ...fonts.modalHeader,
    borderBottom: shapes.border,
    padding: '30px',
  },

  '.MuiDialog-paper .content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
  },

  '.footer': {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: shapes.border,
    padding: '20px',
  },
};

function useIsLoading() {
  const requestStatus = useSelector(
    getRequestStatusSelector('fetchTreatmentPlanTemplates'),
  );
  return !isFinished({ id: 'fetchTreatmentPlanTemplates', requestStatus });
}

function ActivityCarouselItem({ activity }: { activity: Activity }) {
  return (
    <div css={activityCarouselItemCss}>
      {activity.activityDefinition && (
        <ActivityThumbnail activityDefinition={activity.activityDefinition} />
      )}
      <div className="name">{activity.exerciseName}</div>
    </div>
  );
}

function predicate(
  treatmentPlanTemplate: TreatmentPlanTemplate,
  searchTerm: string | undefined,
) {
  return treatmentPlanTemplate.name
    .toLocaleLowerCase()
    .includes(searchTerm?.toLocaleLowerCase() ?? '');
}

function TemplateViewModal({
  template,
  setTemplateIdToView,
  onSelect,
}: {
  template: TreatmentPlanTemplate;
  setTemplateIdToView: Dispatch<SetStateAction<string>>;
  onSelect: (template: TreatmentPlanTemplate) => void;
}) {
  return (
    <Dialog
      open={true}
      onClose={() => setTemplateIdToView('')}
      css={templateViewModalCss}
    >
      <div className="header">{template.name}</div>
      <DialogContent className="content">
        <TreatmentPlanPreview treatmentPlan={template} />
      </DialogContent>
      <div className="footer">
        <button
          css={{ ...buttons.secondary }}
          type="button"
          onClick={() => setTemplateIdToView('')}
        >
          Cancel
        </button>
        <button
          css={{ ...buttons.primary }}
          type="button"
          onClick={() => {
            onSelect(template);
            setTemplateIdToView('');
          }}
        >
          Use Template
        </button>
      </div>
    </Dialog>
  );
}

function Header({
  treatmentPlanTemplate,
  onSelect,
  setTemplateIdToView,
}: {
  treatmentPlanTemplate: TreatmentPlanTemplate;
  onSelect: (template: TreatmentPlanTemplate) => void;
  setTemplateIdToView: (id: string) => void;
}) {
  const dispatch = useAppDispatch();
  const [showHiddenButtons, setShowHiddenButtons] = useState<boolean>(false);

  return (
    <div className="header">
      <h2>{treatmentPlanTemplate.name}</h2>
      <div css={actionsAreaCss}>
        <div
          css={actionsAreaCss}
          onMouseLeave={() => {
            setShowHiddenButtons(false);
          }}
        >
          <button
            hidden={!showHiddenButtons}
            onClick={() => {
              AskUser.getInstance()
                .askUser({
                  msg: messages.onSelectTemplate ?? '',
                  answers: ['Cancel', 'Yes'],
                })
                .then((userAnswer) => {
                  if (userAnswer !== 'Yes') {
                    return;
                  }
                  dispatch(
                    setEditTemplateIdAction({
                      editTemplateId: treatmentPlanTemplate.id,
                    }),
                  );
                });
            }}
          >
            <EditIcon height="20" width="20" />
          </button>
          <button
            hidden={!showHiddenButtons}
            onClick={() => {
              dispatch(
                deleteTreatmentPlanTemplateAction({
                  treatmentPlanTemplate,
                }),
              );
            }}
          >
            <TrashIcon height="22" width="20" css={{ color: colors.blue3 }} />
          </button>
        </div>
        <button
          hidden={showHiddenButtons}
          onClick={() => {
            setShowHiddenButtons(true);
          }}
        >
          <MoreHorizIcon height="22" css={{ color: colors.blue3 }} />
        </button>
        <button
          type="button"
          value={treatmentPlanTemplate.id}
          onClick={(event) => {
            setTemplateIdToView(event.currentTarget.value);
          }}
        >
          <ViewEyeIcon height="24" width="24" />
        </button>
        <button
          onClick={() => {
            onSelect(treatmentPlanTemplate);
          }}
        >
          <AddCircleIcon height="24" width="24" />
        </button>
      </div>
    </div>
  );
}

export default function TemplateSelection({
  onSelect,
}: {
  onSelect: (template: TreatmentPlanTemplate) => void;
}) {
  const [sortedBy, setSortedBy] = useState<
    'alphabetical' | 'decAlphabetical' | undefined
  >(undefined);
  const [searchTerm, onSearchTermChange] = useState<string | undefined>();
  const treatmentPlanTemplates = useSelector(getTreatmentPlanTemplatesSelector);
  const areTemplatesLoading = useIsLoading();
  const [templateIdToView, setTemplateIdToView] = useState<string>('');

  const templateList = useMemo(() => {
    let list = Object.values(treatmentPlanTemplates).filter(
      (treatmentPlanTemplate) => {
        return predicate(treatmentPlanTemplate, searchTerm);
      },
    );
    if (sortedBy) {
      const factor = sortedBy === 'alphabetical' ? 1 : -1;
      list = list.sort((a, b) => {
        return factor * a.name.localeCompare(b.name);
      });
    }
    return list;
  }, [treatmentPlanTemplates, searchTerm, sortedBy]);

  const onSortByClick = () => {
    switch (sortedBy) {
      case undefined:
        setSortedBy('alphabetical');
        return;
      case 'alphabetical':
        setSortedBy('decAlphabetical');
        return;
      case 'decAlphabetical':
        setSortedBy(undefined);
        return;
    }
  };

  if (areTemplatesLoading) {
    return <Loader />;
  }

  return (
    <div css={templateSelectionCss}>
      <div css={{ display: 'flex' }}>
        <SearchTextField
          enableAutocomplete
          name={'template'}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          width={289}
        />
        <button type="button" onClick={onSortByClick}>
          <SortByAlphaIcon />
        </button>
      </div>
      <div css={templateListCss}>
        {templateList.map((treatmentPlanTemplate) => {
          return (
            <div
              key={treatmentPlanTemplate.id}
              css={{ ...card, ...templateContainer }}
            >
              <Header
                treatmentPlanTemplate={treatmentPlanTemplate}
                onSelect={onSelect}
                setTemplateIdToView={setTemplateIdToView}
              />
              <div css={{ display: 'flex', overflow: 'hidden' }}>
                <Carousel
                  key={treatmentPlanTemplate.id}
                  items={treatmentPlanTemplate.activities}
                  renderItem={(activity) => (
                    <ActivityCarouselItem activity={activity} />
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
      {treatmentPlanTemplates[templateIdToView] && (
        <TemplateViewModal
          template={treatmentPlanTemplates[templateIdToView]!}
          setTemplateIdToView={setTemplateIdToView}
          onSelect={onSelect}
        />
      )}
    </div>
  );
}
