/** @jsxImportSource @emotion/react */

import Dialog from '@mui/material/Dialog';
import { CSSObject } from '@emotion/react';
import { fonts } from '../../style/fonts';
import { colors } from '../../style/colors';
import { buttons } from '../../style/buttons';
import {
  card,
  cardBodyCss,
  cardFooterCss,
  cardHeaderCss,
} from '../../style/shapes';
import CountdownTimer from '../commons/CountdownTimer';

const refreshModalCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.refresh-modal-header': {
    border: 0,
  },
  '.refresh-modal-body': {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: '25px',
    paddingLeft: '25px',
  },
  '.refresh-modal-footer': {
    border: 0,
    paddingBottom: 20,
  },
};

export default function RefreshSessionModal({
  setModalMode,
  onRefreshSession,
  onSignOut,
  timeoutTime,
}: {
  setModalMode: (isOpen: boolean) => void;
  onRefreshSession: () => void;
  onSignOut: () => void;
  timeoutTime: number;
}) {
  return (
    <Dialog
      css={{
        '.MuiDialog-paper': {
          minWidth: 380,
          maxWidth: 380,
          height: 225,
        },
        '.MuiDialog-container': { height: '100%' },
      }}
      open={true}
      onClose={() => {
        setModalMode(false);
      }}
    >
      <div css={refreshModalCss}>
        <div
          css={{
            ...card,
            border: 0,
            '.loader-background': {
              backgroundColor: `${colors.dividerGrey}80`,
            },
          }}
        >
          <div className={'refresh-modal-body'}>
            <div className={'refresh-modal-header'} css={cardHeaderCss}>
              Session Timeout
            </div>
            <div className={'refresh-modal-body'} css={cardBodyCss}>
              <div
                css={{
                  ...fonts.text,
                  ...{ paddingBottom: '15px' },
                }}
              >
                <span
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span css={{ ...fonts.text }}>
                    You will be signed out due to inactivity in
                  </span>
                  <span
                    css={{
                      ...fonts.largeLabel,
                      display: 'flex',
                      marginLeft: '0.2em',
                    }}
                  >
                    <CountdownTimer
                      timeInSeconds={timeoutTime}
                      onTimerEnd={() => onSignOut()}
                    />
                  </span>
                </span>
                <span css={{ ...fonts.largeLabel, marginLeft: '0.2em' }}>
                  seconds.
                </span>
                <span css={{ marginLeft: '0.2em' }}>
                  To continue your session, please select
                </span>
                <span css={{ ...fonts.largeLabel }}> Stay Signed In</span>.
              </div>
            </div>
            {
              <div className={'refresh-modal-footer'} css={cardFooterCss}>
                <button
                  css={{ ...buttons.secondary }}
                  type="button"
                  onClick={onSignOut}
                >
                  Sign Out Now
                </button>
                <button
                  css={{ ...buttons.primary }}
                  type="button"
                  onClick={onRefreshSession}
                >
                  Stay Signed In
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </Dialog>
  );
}
