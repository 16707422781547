import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Authenticator, View, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import {
  AWS_REGION,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEB_CLIENT_ID,
} from './externalConfig';
import PtMetricsLogoIcon from './assets/ptMetricsLogo.svg';
import { fonts } from './style/fonts';
import { signInCss } from './style/signIn'; // Import your styles
import { Global } from '@emotion/react';
import RefreshSessionModal from './components/app/RefreshSessionModal';
import { HubCallback, HubCapsule } from '@aws-amplify/core';
import { validateEmail } from './components/forms/create-user-form/userFormValidation';
import App from './App';
import useSignOut from './hooks/useSignOut';

Amplify.configure({
  Auth: {
    region: AWS_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
    storage: sessionStorage,
  },
});

const components = {
  Header(username: string) {
    const currentState = useAuthenticator();
    let headerText;
    let headline;

    if (currentState.route == 'confirmResetPassword') {
      headerText = 'Reset Password';
      headline = `Check your email for a code sent to ${username}, 
      Check your spam folder if you don’t see the email. 
      Enter your new password below.`;
    } else if (currentState.route == 'resetPassword') {
      headerText = 'Forgot Password';
      headline =
        'Please enter your email address to receive a message with a code to reset your password.';
    } else if (currentState.route == 'signIn') {
      headerText = 'Sign In';
      headline = null;
    } else if (currentState.route == 'forceNewPassword') {
      headerText = 'Reset Password';
      headline = 'Enter your new password below.';
    }
    return (
      <View
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          alignSelf: 'center',
          margin: 'auto',
          flexDirection: 'column',
          paddingTop: '60px',
        }}
        textAlign="center"
      >
        <PtMetricsLogoIcon width="371" height="70" />
        <div style={fonts.modalHeader as CSSProperties}>{headerText}</div>
        {headline && (
          <div
            style={{
              ...(fonts.text as CSSProperties),
              width: '500px',
              paddingTop: '20px',
            }}
          >
            {headline}
          </div>
        )}
      </View>
    );
  },
  ForceNewPassword: {
    FormFields() {
      const [password, setPassword] = useState('');

      // Function to check password against the rules
      const checkPasswordStrength = () => {
        return {
          minLength: password.length >= 12,
          hasUpper: /[A-Z]/.test(password),
          hasLower: /[a-z]/.test(password),
          hasNumber: /[0-9]/.test(password),
          hasSpecialChar: /[!@#\$%\^\&*\)\(+=._-]+/.test(password),
        };
      };

      const passwordRules = checkPasswordStrength();

      useEffect(() => {
        const handlePasswordChange = (e: Event) => {
          setPassword((e.target as HTMLInputElement)?.value);
        };

        const passwordInput = document.querySelector('input[name="password"]');
        passwordInput?.addEventListener('input', handlePasswordChange);

        return () => {
          passwordInput?.removeEventListener('input', handlePasswordChange);
        };
      }, []);

      useEffect(() => {
        const changePasswordButton = document.querySelector(
          '.amplify-button--primary[type="submit"]',
        ) as HTMLButtonElement;
        if (changePasswordButton) {
          changePasswordButton.disabled =
            !Object.values(passwordRules).every(Boolean);
          changePasswordButton.classList.toggle(
            'disabled',
            changePasswordButton.disabled,
          );
        }
      }, [passwordRules]);

      return (
        <>
          <Authenticator.ForceNewPassword.FormFields />
          <div style={fonts.text as CSSProperties}>
            {!passwordRules.minLength && <div>Minimum of 12 characters</div>}
            {!passwordRules.hasUpper && (
              <div>At least one uppercase letter</div>
            )}
            {!passwordRules.hasUpper && (
              <div>At least one lowercase letter</div>
            )}
            {!passwordRules.hasNumber && <div>At least one digit (0-9)</div>}
            {!passwordRules.hasSpecialChar && (
              <div>At least one special characters ($ % & ! etc.)</div>
            )}
          </div>
        </>
      );
    },
  },
};

function AppWithLogin() {
  const INACTIVITY_LIMIT = 3600000;
  const TIME_REFRESH_TOKEN_MSG = 60;
  const [inactivityTimeout, setInactivityTimeout] = useState<
    number | undefined
  >(undefined);
  const [showSessionTimeOutModal, setShowSessionTimeOutModal] =
    useState<boolean>(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>('');
  const signOut = useSignOut();
  const authChannel = useRef<BroadcastChannel | undefined>();

  const handleSignOut = async () => {
    try {
      await signOut();
      setShowSessionTimeOutModal(false);
      localStorage.removeItem('session_timeout_modal');
    } catch (error) {}
  };

  const checkInactivity = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const currentTime = Date.now();
        const lastActivityTime = localStorage.getItem('last_activity_time');
        if (currentTime - Number(lastActivityTime) > INACTIVITY_LIMIT) {
          setShowSessionTimeOutModal(true);
          localStorage.setItem('session_timeout_modal', 'true');
        }
      }
    } catch (error) {}
  };

  const resetActivityTimer = () => {
    const oldValue = window.localStorage.getItem('last_activity_time');
    const newValue = Date.now().toString();
    localStorage.setItem('last_activity_time', newValue);
    const event = new StorageEvent('storage', {
      key: 'last_activity_time',
      oldValue,
      newValue,
    });

    window.dispatchEvent(event);
  };

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('auth-channel');
    authChannel.current = broadcastChannel;
    return () => {
      broadcastChannel.close();
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('last_activity_time', Date.now().toString());
    const listener: HubCallback = (data: HubCapsule) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        authChannel.current?.postMessage(payload.event);

        if (showSessionTimeOutModal) {
          setShowSessionTimeOutModal(false);
          localStorage.removeItem('session_timeout_modal');
        }
      }
      if (payload.event === 'signIn') {
        authChannel.current?.postMessage(payload.event);
      }
    };

    Hub.listen('auth', listener);

    const onStorageChange = (event: StorageEvent) => {
      if (event.key === 'last_activity_time') {
        if (inactivityTimeout) {
          clearTimeout(inactivityTimeout);
        }
        const timeout = window.setTimeout(checkInactivity, INACTIVITY_LIMIT);
        setInactivityTimeout(timeout);
      }
      if (event.key === 'session_timeout_modal') {
        if (event.newValue === 'true') {
          setShowSessionTimeOutModal(true);
        }
        if (!event.newValue) {
          setShowSessionTimeOutModal(false);
        }
      }
    };

    window.addEventListener('storage', onStorageChange);
    authChannel.current?.addEventListener('message', () => {
      window.location.reload();
    });

    const events = [
      'mousemove',
      'mousedown',
      'keypress',
      'scroll',
      'touchstart',
    ];
    events.forEach((event) =>
      window.addEventListener(event, resetActivityTimer),
    );

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetActivityTimer),
      );
      Hub.remove('auth', listener);
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const handleForgotPassword = (username: string) => {
    if (!validateEmail(username)) {
      throw new Error('Please enter a valid email address');
    }
    return Auth.forgotPassword(username)
      .then((data) => {
        setResetPasswordEmail(username);
        return data;
      })
      .catch((error) => {
        if (error.name !== 'UserNotFoundException') {
          throw error;
        }
      });
  };

  const handleSignIn = ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    if (!validateEmail(username)) {
      throw new Error('Please check the email format and remove any spaces.');
    } else {
      return Auth.signIn(username, password)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const handleStaySignedIn = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const refreshToken = currentUser?.signInUserSession.refreshToken;
      currentUser.refreshSession(refreshToken, (err?: any, session?: any) => {
        if (session) {
          setShowSessionTimeOutModal(false);
          localStorage.removeItem('session_timeout_modal');
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    let storageValue = localStorage.getItem('session_timeout_modal');
    if (showSessionTimeOutModal && !storageValue) {
      localStorage.setItem('session_timeout_modal', 'true');
    } else if (storageValue && !showSessionTimeOutModal) {
      localStorage.removeItem('session_timeout_modal');
    }
  }, [showSessionTimeOutModal]);

  return (
    <>
      <Global styles={signInCss} />
      {showSessionTimeOutModal && (
        <RefreshSessionModal
          timeoutTime={TIME_REFRESH_TOKEN_MSG}
          setModalMode={setShowSessionTimeOutModal}
          onRefreshSession={handleStaySignedIn}
          onSignOut={handleSignOut}
        />
      )}
      <Authenticator
        formFields={{
          signIn: { username: { label: 'Email' } },
          resetPassword: {
            username: {
              label: 'Enter your email address',
            },
          },
        }}
        hideSignUp={true}
        components={{
          Header: () => components.Header(resetPasswordEmail),
          ForceNewPassword: components.ForceNewPassword,
        }}
        services={{
          handleForgotPassword,
          handleSignIn,
        }}
      >
        <App />
      </Authenticator>
    </>
  );
}

export default function AppWithAuth() {
  return (
    <Authenticator.Provider>
      <AppWithLogin />
    </Authenticator.Provider>
  );
}
