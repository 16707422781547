import { useAuthenticator } from '@aws-amplify/ui-react';
import store from '../state-manager/store';
import { resetAction } from '../state-manager/actions/resetAction';
import { useEffect, useRef } from 'react';

export default function useSignOut() {
  const { signOut, authStatus } = useAuthenticator((context) => [context.route]);
  const prevState = useRef<string>(authStatus);

  useEffect(() => {
    if (prevState.current === 'authenticated' && authStatus === 'unauthenticated') {
      window.postMessage('message');
      store.dispatch(resetAction());
      location.href = '/';
    }
    prevState.current = authStatus;
  }, [authStatus]);

  return signOut;
}
