/** @jsxImportSource @emotion/react */
import { v4 as uuid } from 'uuid';

import { colors } from '../../../../style/colors';
import {
  card,
  cardBodyCss,
  cardFooterCss,
  cardHeaderCss,
} from '../../../../style/shapes';
import { useForm } from '../../../commons/form/useForm';
import {
  InputField,
  SelectInputField,
} from '../../../commons/form/forStateFrom';
import { buttons } from '../../../../style/buttons';
import Loader from '../../../commons/Loader';
import { clinicianFormValidation } from '../userFormValidation';
import { useAppDispatch } from '../../../../state-manager/store';
import { notify } from '../../../../state-manager/slices/notificationsSlice';
import {
  createUserAction,
  updateUserAction,
} from '../../../../state-manager/thunks/usersThunks';
import { useEffect, useRef } from 'react';
import { User } from '../../../../models/User';
import {
  ClinicianFormData,
  ClinicianInput,
  getCancelWithConfirm,
  getTitle,
  isDisabled,
  UserTypes,
} from '../CreateUserUtils';
import { cleanRequestStatusAction } from '../../../../state-manager/slices/appSlice';
import {
  useInProcess,
  useIsEndedSuccessfully,
  useIsFailed,
  useIsFinished,
} from '../../../../state-manager/selectors/appSelectors';
import { fonts } from '../../../../style/fonts';
import { FormMode } from '../../../commons/form/formUtils';

const clinicianPrefixOptions = ['Dr.'];

function isClinicianInput(
  userFormData: ClinicianFormData,
): userFormData is ClinicianInput {
  const formErrors = clinicianFormValidation(userFormData);
  return !formErrors;
}

function toClinicianFormData(user: User): ClinicianFormData {
  const additionalInfo = user.additionalInfo;

  const userFormData = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    creationDate: new Date(user.creationDate),
    prefix: additionalInfo?.prefix,
    isTestUser: false,
    role: user.role,
  };

  return userFormData;
}

export default function CreateClinicianFormView({
  onCreateUser,
  formMode,
  onFinish,
  onCancel,
}: {
  onCreateUser: (name?: string) => void;
  formMode: FormMode<User>;
  onFinish: () => void;
  onCancel?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { mode } = formMode;
  let initData: User | undefined;
  if ('initData' in formMode) {
    initData = formMode.initData;
  }

  const initFormData = useRef<ClinicianFormData>(
    initData
      ? toClinicianFormData(initData)
      : {
          id: uuid(),
          creationDate: new Date(),
          isTestUser: false,
          role: UserTypes.Clinician,
        },
  );

  useEffect(() => {
    if (!initFormData.current) {
      return;
    }
    const id = initFormData.current.id;
    dispatch(
      cleanRequestStatusAction({
        id,
        requestStatusName: 'createNewUser',
      }),
    );
    dispatch(
      cleanRequestStatusAction({
        id,
        requestStatusName: 'updateUser',
      }),
    );
  }, [initFormData.current]);

  const {
    formData,
    formErrors,
    setFormErrors,
    setShouldValidate,
    isDirty,
    onChange,
  } = useForm(initFormData.current, clinicianFormValidation);

  const loading = useInProcess(formData?.id, ['createNewUser', 'updateUser']);
  const creationFinished = useIsFinished(formData?.id, ['createNewUser']);
  const creationFailed = useIsFailed(formData?.id, ['createNewUser']);
  const creationEndedSuccessfully = useIsEndedSuccessfully(formData?.id, [
    'createNewUser',
  ]);
  const updateEndedSuccessfully = useIsEndedSuccessfully(formData?.id, [
    'updateUser',
  ]);

  useEffect(() => {
    if (formData && updateEndedSuccessfully === true) {
      onFinish();
    }
  }, [updateEndedSuccessfully]);

  useEffect(() => {
    if (formData && creationEndedSuccessfully === true) {
      onCreateUser(`${formData.firstName}`);
    }
  }, [creationEndedSuccessfully]);

  if (!formData) {
    return <></>;
  }

  const disabled =
    mode === 'preview' || (!creationFinished && (loading || creationFailed));
  const shared = { disabled, formErrors, formData, onChange };

  const onSubmit = async () => {
    setShouldValidate(true);
    if (!formData) {
      return;
    }
    const newFormErrors = clinicianFormValidation(formData);
    if (!newFormErrors) {
      if (!isClinicianInput(formData)) {
        return;
      }
      if (mode === 'new') {
        try {
          await dispatch(createUserAction(UserTypes.Clinician, formData));
        } catch (error) {
          if (error.message.includes('Email')) {
            dispatch(
              notify({
                message: error.message,
                severity: 'error',
              }),
            );
            setFormErrors({ email: error.message });
          }
        }
      } else if (mode === 'edit') {
        dispatch(updateUserAction(UserTypes.Clinician, formData, formData.id));
      }
    }
  };

  return (
    <div
      css={{
        ...card,
        '.loader-background': { backgroundColor: `${colors.dividerGrey}80` },
      }}
    >
      <form noValidate aria-disabled autoComplete="off">
        <div css={{ ...cardHeaderCss, alignItems: 'center' }}>
          <span>{getTitle(formMode, UserTypes.Clinician)}</span>
          <span css={{ ...fonts.text }}>
            <span css={{ color: colors.red }}>*</span> Required Fields
          </span>
        </div>
        <div css={cardBodyCss}>
          <SelectInputField
            {...shared}
            name="prefix"
            label="Prefix"
            options={clinicianPrefixOptions}
            disabled={isDisabled(UserTypes.Clinician, 'prefix', disabled, mode)}
          />
          <InputField
            {...shared}
            name={'firstName'}
            label="First Name"
            required
            disabled={isDisabled(
              UserTypes.Clinician,
              'firstName',
              disabled,
              mode,
            )}
          />
          <InputField
            {...shared}
            name="lastName"
            label="Last Name"
            required
            disabled={isDisabled(
              UserTypes.Clinician,
              'lastName',
              disabled,
              mode,
            )}
          />
          <InputField
            {...shared}
            name="email"
            label="Email Address"
            required
            disabled={isDisabled(UserTypes.Clinician, 'email', disabled, mode)}
          />
        </div>
        <div css={cardFooterCss}>
          {onCancel && (
            <button
              css={{ ...buttons.secondary }}
              type="button"
              onClick={getCancelWithConfirm(onCancel, isDirty)}
            >
              Cancel
            </button>
          )}
          <button css={{ ...buttons.primary }} type="button" onClick={onSubmit}>
            Save
          </button>
        </div>
      </form>
      {loading && <Loader />}
    </div>
  );
}
