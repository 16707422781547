import { colors } from '../../../style/colors';
import { shapes } from '../../../style/shapes';
import type { Session } from '../../../models/Session';
import { MovementExecutionMetricResults } from '../../../types/backendType';
import { TreatmentPlan } from '../../../models/TreatmentPlan';
import { Activity } from '../../../models/Activity';
import { isEmpty } from '../../../state-manager/utils/compare';
import { fonts } from '../../../style/fonts';

export type OverTimeResult = {
  metricKey: string;
  value: number[];
  side: string;
  name: string;
  unit: string;
  standardDeviation: number | undefined;
};

// Find repeated metric key which denotes a two-sided metric
export function getTwoSidedOverTimeMetricKeys(
  overTimeMetricsArray: MovementExecutionMetricResults[],
) {
  const duplicateMetricNameArray = overTimeMetricsArray
    .map((overTimeMetric, overTimeMetricIndex) => {
      const isDuplicateMetricName = overTimeMetricsArray.find(
        (secondOverTimeMetric, secondOverTimeMetricIndex) => {
          return (
            overTimeMetricIndex !== secondOverTimeMetricIndex &&
            overTimeMetric.metric_name === secondOverTimeMetric.metric_name
          );
        },
      );
      if (isDuplicateMetricName) {
        return overTimeMetric.metric_name;
      }
    })
    .filter(Boolean);

  const duplicateMetricNameSet = new Set(duplicateMetricNameArray);
  return [...duplicateMetricNameSet];
}

export function isMetricTwoSided(metricKey: string) {
  return metricKey.includes('left') || metricKey.includes('right');
}
export function stripTwoSidedMetricKeyOfSide(metricKey: string) {
  const splitKey = metricKey.split('_');
  return splitKey.slice(0, splitKey.length - 1).join('_');
}

export function normalizeMetricKey(metricKey: string) {
  return isMetricTwoSided(metricKey)
    ? stripTwoSidedMetricKeyOfSide(metricKey)
    : metricKey;
}

export const SESSION_STATUSES_TO_DISPLAY = [
  'Skipped',
  'Aborted',
  'PartialPatientSkipped',
  'Error',
];

// Part of the legacy implementation of the Partially Completed Column of Sessions Results Table
export const LEGACY_SESSION_STATUSES_TO_DISPLAY = [
  'PartiallyCompleted',
  'NotStarted',
];
// PressedBack and PressedAbort are legacy values with the same meaning
// They were merged and renamed to PressedTerminate
// They have been left here for backwards compatibility for the time being
export enum DefaultLegacyStatusDescriptions {
  PressedBack = 'Pressed Back',
  PressedAbort = 'Pressed Abort',
  PressedFinish = 'Pressed Finish',
  EndConditionMet = 'Algo end condition met',
  PressedSkip = 'Pressed Skip',
  PressedSkipPreChecks = 'Pressed Skip Pre-checks',
  PressedTerminate = 'Pressed Terminate',
}

export function getLegacyPartiallyCompletedText(
  statusDescription: DefaultLegacyStatusDescriptions | string,
) {
  if (
    !Object.values(DefaultLegacyStatusDescriptions).includes(
      statusDescription as DefaultLegacyStatusDescriptions,
    )
  ) {
    return `Skipped by patient\nReason: ${statusDescription}`;
  }
  if (
    statusDescription === DefaultLegacyStatusDescriptions.PressedAbort ||
    statusDescription === DefaultLegacyStatusDescriptions.PressedBack ||
    statusDescription === DefaultLegacyStatusDescriptions.PressedTerminate
  ) {
    return 'Aborted by patient';
  }
  if (
    statusDescription === DefaultLegacyStatusDescriptions.PressedSkip ||
    statusDescription === DefaultLegacyStatusDescriptions.PressedSkipPreChecks
  ) {
    return 'Skipped by Patient';
  }
}

export const simplifiedActivityGraphTooltipCss = {
  tooltip: {
    sx: {
      width: '500px',
      height: '160px',
      padding: '0 20px',
      maxWidth: 'unset',
      backgroundColor: colors.white,
      border: shapes.border,
      boxShadow: `1px 1px 5px 0px ${colors.dividerGrey}`,
      pointerEvents: 'none',
    },
  },
  arrow: {
    sx: {
      color: colors.white,
      '&:before': {
        border: `1px solid ${colors.dividerGrey}`,
      },
    },
  },
};

export const complianceCustomTooltipCss = {
  tooltip: {
    sx: {
      ...fonts.text,
      backgroundColor: colors.white,
      color: colors.black,
      boxShadow: '0px 4px 4px 0px #00000040',
      padding: '8px 12px',
      borderRadius: '0',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      maxWidth: 'fit-content',
      position: 'relative',
    },
  },
  tooltipArrow: {
    sx: {
      backgroundColor: colors.white,
      boxShadow: '0px 4px 4px 0px #00000040',
    },
  },
  arrow: {
    sx: {
      '&:before': {
        backgroundColor: colors.white,
        boxShadow: '0px 4px 4px 0px #00000040',
      },
    },
  },
};

export function getPlanActivitiesWithoutExecution(
  sessions: Session[] | undefined,
  lastTreatmentPlanOfDay: TreatmentPlan | undefined,
) {
  if (!sessions || !lastTreatmentPlanOfDay) {
    return;
  }
  const allSessionsActivities = sessions
    ?.map((session) => session.activitiesExecutions)
    .flat();
  const activitiesWithoutExecution = lastTreatmentPlanOfDay.activities.filter(
    (planActivity) => {
      return !allSessionsActivities.find((execution) => {
        return (
          lastTreatmentPlanOfDay.id === execution.treatmentPlanId &&
          execution.activityDefinitionId === planActivity.activityDefinitionId
        );
      });
    },
  );
  return activitiesWithoutExecution;
}

// Daily Compliance is calculated as in Patient App
// Notes: It is timezone dependant and accounts only
// for non-executed activities of the last TP
export function getDailyCompliance(
  sessions: Session[] | undefined,
  lastTreatmentPlanOfDay: TreatmentPlan | undefined,
) {
  let numberOfExecutions = 0;

  const totalSessionCompliance = sessions?.reduce(
    (sessionAccumulator, session) => {
      const sessionComplianceArray = session.activitiesExecutions.reduce(
        (accumulator, activity) => {
          return accumulator + activity.compliance;
        },
        0,
      );
      numberOfExecutions += session.activitiesExecutions.length;
      return sessionAccumulator + sessionComplianceArray;
    },
    0,
  );

  if (!totalSessionCompliance) {
    if (totalSessionCompliance === 0) {
      return { value: 0 };
    }
    return;
  }

  const activitiesWithoutExecution = getPlanActivitiesWithoutExecution(
    sessions,
    lastTreatmentPlanOfDay,
  );

  if (!activitiesWithoutExecution?.length) {
    return { value: Math.round(totalSessionCompliance / numberOfExecutions) };
  }
  if (activitiesWithoutExecution.length) {
    return {
      value: Math.round(
        totalSessionCompliance /
          (numberOfExecutions + activitiesWithoutExecution.length),
      ),
      activitiesWithoutExecution: activitiesWithoutExecution,
    };
  }
}

export function getDailyComplianceGaps(
  sessions: Session[] | undefined,
  lastTreatmentPlanOfDay: TreatmentPlan | undefined,
): string {
  if (!sessions || sessions.length === 0) {
    return 'No sessions available.';
  }

  const complianceGaps: string[] = [];

  sessions.forEach((session) => {
    session.activitiesExecutions.forEach((activityExecution) => {
      const compliance = activityExecution.compliance;
      const activityName = activityExecution.name || 'Unknown Activity';
      const reason =
        getLegacyPartiallyCompletedText(
          activityExecution.statusDescription,
        )?.split(' ')?.[0] || 'Unknown Reason';

      if (compliance < 100) {
        complianceGaps.push(`${activityName} ${compliance}% - ${reason}`);
      }
    });
  });

  const activitiesWithoutExecution =
    getPlanActivitiesWithoutExecution(sessions, lastTreatmentPlanOfDay) || [];

  activitiesWithoutExecution.forEach((activity: Activity) => {
    complianceGaps.push(
      `${activity.activityDefinition?.name} 0% - Not Started`,
    );
  });

  if (complianceGaps.length === 0 && activitiesWithoutExecution.length === 0) {
    return 'All activities are fully compliant.';
  }
  return complianceGaps.join('\n');
}

// To be extended in the near future to handle other compliance reasons
export function getComplianceTooltipText(activities: Activity[] | undefined) {
  if (!activities || !activities.length) {
    return '';
  }

  return activities.map((activity) => {
    return `${activity.exerciseName} - Not Started`;
  });
}

// Used to form an array with a number of elements
// correspending to the number of sets
// Used for results such as sets and duration
export function getMovementResultsArray(
  activitySets: number | undefined,
  activityResults: number[] | undefined,
) {
  if (!isEmpty(activitySets)) {
    if (!activityResults) {
      return [...Array(activitySets).fill(0)];
    }

    if (activitySets === activityResults?.length) {
      return activityResults;
    }

    if (activitySets > activityResults.length) {
      const difference = activitySets - activityResults.length;
      const paddedArray = [...activityResults, ...Array(difference).fill(0)];
      return paddedArray;
    }
  }
}

export function getShouldShowLegacyPartiallyCompleted(
  activityExecutionStatus: string,
  activityExecutionStatusDescription: string,
) {
  return (
    LEGACY_SESSION_STATUSES_TO_DISPLAY.includes(activityExecutionStatus) &&
    !(
      activityExecutionStatusDescription ===
        DefaultLegacyStatusDescriptions.EndConditionMet ||
      activityExecutionStatusDescription ===
        DefaultLegacyStatusDescriptions.PressedFinish
    )
  );
}

export function getShouldShowPartiallyCompleted(
  activityExecutionStatus: string,
  activityAutomationLevel: number | undefined,
) {
  return (
    SESSION_STATUSES_TO_DISPLAY.includes(activityExecutionStatus) &&
    activityAutomationLevel !== 0
  );
}
