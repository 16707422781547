import { isDate as isDateFns } from 'date-fns';

export function createDate({
  days,
  hours,
  minutes,
}: {
  days?: number;
  hours?: number;
  minutes?: number;
}) {
  const date = new Date();
  if (days) {
    date.setDate(date.getDate() + days);
  }
  if (hours) {
    date.setDate(date.getHours() + hours);
  }
  if (minutes) {
    date.setDate(date.getMinutes() + minutes);
  }
  return date;
}

export function toDatePickerFormat(date: Date) {
  let year;
  let month;
  let day;
  year = date.getFullYear();
  month = (date.getMonth() + 1).toString().padStart(2, '0');
  day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

// Create a Date object at midnight in the local time zone
export function createDateFromDatePickerString(
  datePickerString: string,
  absoluteDate?: boolean | undefined,
) {
  const [year, month, day] = datePickerString.split('-').map(Number); //2023-11-14
  let date;
  if (year !== undefined && month !== undefined && day !== undefined) {
    if (absoluteDate) {
      date = new Date(Date.UTC(year, month - 1, day));
    } else {
      date = new Date(year, month - 1, day);
    }
    return date;
  }
}

export function isDate(value: any): value is Date {
  return isDateFns(value);
}

export const dateSortingFn = (rowA: any, rowB: any, columnId: string) => {
  const dateA = new Date(rowA.getValue(columnId));
  const dateB = new Date(rowB.getValue(columnId));
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};
