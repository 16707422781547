/** @jsxImportSource @emotion/react */

import React, {
  JSXElementConstructor,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { CSSObject } from '@emotion/react';
import { intervalToDuration, isSameDay } from 'date-fns';

import SessionsDates from './SessionsDates';
import SessionsView from './SessionsView';
import { fonts } from '../../../style/fonts';
import { shapes } from '../../../style/shapes';
import {
  Session,
  getPatientSessionsSelector,
  groupSessionsByDate,
} from '../../../models/Session';
import { colors } from '../../../style/colors';
import { isEmpty } from '../../../state-manager/utils/compare';
import SessionsDigest, { TableMetric } from './SessionsDigest';
import {
  getPatientActivitiesExecutionsSelector,
  groupActivitiesExecutionsByActivityDefinition,
} from '../../../models/ActivityExecution';
import {
  getComplianceTooltipText,
  getDailyCompliance,
  getDailyComplianceGaps,
} from './SessionsUntils';
import { getPatientTreatmentPlansSelector } from '../../../models/factories/treatmentPlanFactories';
import { MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import {
  getCurrentPatientSelector,
  getUsersSelector,
} from '../../../models/factories/userFactories';
import usePdfExport from '../../../hooks/usePdfExport';
import ChevronIcon from '../../../assets/chevron.svg';
import SessionsPDF from './SessionsPDF';
import { buttons } from '../../../style/buttons';
import {
  getMeasurementSystemSelector,
  getSelectedCaseIdSelector,
} from '../../../state-manager/selectors/appSelectors';
import SessionInsightsPDF from './SessionInsightsPDF';

const SESSIONS_HEADER_HEIGHT = 71;
const SESSIONS_DATES_WIDTH = 140;

enum SelectValue {
  Export = 'Export',
  Loading = 'Loading...',
  AllSessionDetails = 'allSessionDetails',
  TopInsights = 'topSessionInsights',
}

const sessionsContainerCss: CSSObject = {
  display: 'flex',
  width: '100%',
  border: shapes.border,
  backgroundColor: colors.white,
  overflow: 'auto',
  '.sessions-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: SESSIONS_HEADER_HEIGHT,
    width: '100%',
    padding: '0 20px',
    borderBottom: shapes.border,
    ...fonts.modalHeader,

    '.sessions-header-subcontainer': {
      display: 'flex',
      alignItems: 'center',
      gap: '40px',
    },
  },
  '.sessions-header-split-panel': {
    maxHeight: '45px',
    display: 'flex',
    border: shapes.border,
    borderRadius: shapes.borderRadius,

    '& > div': {
      display: 'flex',
      alignItems: 'baseline',
      padding: '11px 20px',
      gap: '3px',
    },

    '.total-duration': {
      borderRight: shapes.border,
      whiteSpace: 'nowrap',
    },

    label: {
      ...fonts.h2,
    },

    span: {
      ...fonts.largeText,
    },
  },
  '.sessions-body': {
    padding: 20,
    height: `calc(100% - ${SESSIONS_HEADER_HEIGHT}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  '.sessions-dates': {
    height: '100%',
    width: SESSIONS_DATES_WIDTH,
    borderRight: shapes.border,
  },
  '.sessions': {
    height: '100%',
    width: `calc(100% - ${SESSIONS_DATES_WIDTH}px)`,
    minWidth: '906px',
  },
  '.export-button': {
    ...buttons.secondary,

    '.MuiSelect-select': {
      padding: 0,
      position: 'relative',
      overflow: 'visible',
    },
    '.MuiOutlinedInput-notchedOutline': { border: 'none' },
    minWidth: '99px',
    height: '40px',
  },
  '.export-button-disabled': {
    borderColor: colors.dividerGrey,
  },
};

export default function SessionsContainer() {
  const patientSessionsSelector = useSelector(getPatientSessionsSelector);
  const patientActivitiesExecutions = useSelector(
    getPatientActivitiesExecutionsSelector,
  );
  const patientTreatmentPlans = useSelector(getPatientTreatmentPlansSelector);
  const patient = useSelector(getCurrentPatientSelector);

  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [sessionsByDateSelector, setSessionsByDateSelector] = useState<
    Record<string, Session[]> | undefined
  >();
  const caseId = useSelector(getSelectedCaseIdSelector);
  const [isSelectOpen, setisSelectOpen] = useState(false);
  const users = useSelector(getUsersSelector);
  const measurementSystem = useSelector(getMeasurementSystemSelector);
  const [digestData, setDigestData] = useState<TableMetric[]>();

  const clinicianName = useMemo(() => {
    if (users) {
      return Object.values(users).find((user) => user.role === 'clinician')
        ?.name;
    }
  }, [users]);

  useEffect(() => {
    const newSessionsByDateSelector = groupSessionsByDate(
      patientSessionsSelector,
    );
    setSessionsByDateSelector(newSessionsByDateSelector);
  }, [patientSessionsSelector]);

  useEffect(() => {
    if (!sessionsByDateSelector) {
      return;
    }
    const defaultDate = Object.keys(sessionsByDateSelector)[0];
    if (defaultDate) {
      setSelectedDate(defaultDate);
    }
  }, [sessionsByDateSelector]);

  let sessions: Session[] | undefined = undefined;
  if (sessionsByDateSelector && selectedDate) {
    sessions = sessionsByDateSelector[selectedDate];
  }
  const treatmentPlanName = sessions?.[0]?.treatmentPlan?.name ?? '';

  const lastTreatmentPlanOfDay = patientTreatmentPlans?.find(
    (treatmentPlan) => {
      if (!selectedDate) {
        return;
      }
      return isSameDay(
        new Date(treatmentPlan.startDate),
        new Date(selectedDate),
      );
    },
  );

  const dailyCompliance = useMemo(() => {
    return getDailyCompliance(sessions, lastTreatmentPlanOfDay);
  }, [sessions, lastTreatmentPlanOfDay]);

  const dailyComplianceGaps = useMemo(() => {
    return getDailyComplianceGaps(sessions, lastTreatmentPlanOfDay);
  }, [sessions, lastTreatmentPlanOfDay]);

  const totalDuration = useMemo(() => {
    const totalDurationInMilliseconds = sessions?.reduce(
      (accumulator, session) => {
        return accumulator + session.duration;
      },
      0,
    );
    if (totalDurationInMilliseconds) {
      const duration = intervalToDuration({
        start: 0,
        end: totalDurationInMilliseconds,
      });
      let formattedTotalDuration = '';
      if (duration.hours) {
        formattedTotalDuration += duration.hours;
        formattedTotalDuration += duration.hours === 1 ? ' Hour ' : ' Hours ';
      }
      if (duration.minutes) {
        formattedTotalDuration += duration.minutes;
        formattedTotalDuration +=
          duration.minutes === 1 ? ' Minute' : ' Minutes';
      }
      return formattedTotalDuration;
    }
  }, [sessions]);

  const activitiesExecutionsMap = useMemo(() => {
    const newActivitiesExecutionsMap =
      groupActivitiesExecutionsByActivityDefinition(
        patientActivitiesExecutions,
      );
    return newActivitiesExecutionsMap;
  }, [patientActivitiesExecutions]);

  const complianceTooltipText = useMemo(() => {
    return getComplianceTooltipText(
      dailyCompliance?.activitiesWithoutExecution,
    );
  }, [dailyCompliance]);

  const commonDocumentProps = {
    patientName: patient?.name,
    caseId,
    duration: totalDuration,
    sessions,
    compliance: dailyCompliance,
    clinicianName,
    measurementSystem,
  };

  const PdfDocumentsMap: Partial<
    Record<
      SelectValue,
      { document: JSXElementConstructor<any> | undefined; documentProps: any }
    >
  > = {
    allSessionDetails: {
      document: SessionsPDF,
      documentProps: commonDocumentProps,
    },
    topSessionInsights: {
      document: SessionInsightsPDF,
      documentProps: {
        ...commonDocumentProps,
        digestData,
        allSessionsByDate: sessionsByDateSelector,
        selectedDate,
        lastTreatmentPlanOfDay,
      },
    },
  };

  const isReadyForExport = Object.values(commonDocumentProps).every(Boolean);

  const [selectValue, setSelectValue] = useState<SelectValue>(
    isReadyForExport ? SelectValue.Export : SelectValue.Loading,
  );

  const { initiatePdfDownload, isDownloading } = usePdfExport(
    patient?.name,
    selectedDate,
  );

  const onSelectClose = () => {
    setisSelectOpen(false);
  };

  const onSelectOpen = () => {
    setisSelectOpen(true);
  };

  const onSelectChange = (event: SelectChangeEvent) => {
    const eventValue = event.target.value as SelectValue;

    if (
      Object.values(SelectValue).includes(eventValue) &&
      PdfDocumentsMap[eventValue]
    ) {
      setSelectValue(eventValue);

      initiatePdfDownload(
        event,
        PdfDocumentsMap[eventValue]?.document,
        PdfDocumentsMap[eventValue]?.documentProps,
      );
    }
  };

  const onSelectClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (selectValue && PdfDocumentsMap[selectValue]) {
      initiatePdfDownload(
        event,
        PdfDocumentsMap[selectValue]?.document,
        PdfDocumentsMap[selectValue]?.documentProps,
      );
    }
  };

  return (
    <div className="sessions-container" css={sessionsContainerCss}>
      <div className="sessions-dates">
        <div className="sessions-header">Date</div>
        <div className="sessions-body">
          {sessionsByDateSelector && (
            <SessionsDates
              sessionsByDateSelector={sessionsByDateSelector}
              onClick={setSelectedDate}
              selectedDate={selectedDate}
            />
          )}
        </div>
      </div>
      <div className="sessions">
        <div className="sessions-header">
          <div className="sessions-header-subcontainer">
            <span>{`${selectedDate ?? ''} - ${treatmentPlanName}`}</span>
            <div className="sessions-header-split-panel">
              {totalDuration && (
                <div className="total-duration">
                  <label>Total Duration:</label>
                  <span>{totalDuration}</span>
                </div>
              )}
              <div>
                <label>Compliance:</label>
                {dailyCompliance && !isEmpty(dailyCompliance.value) && (
                  <Tooltip
                    title={
                      complianceTooltipText ? (
                        <div css={{ display: 'flex', flexDirection: 'column' }}>
                          {complianceTooltipText.map((text, index) => (
                            <span key={index}>{text}</span>
                          ))}
                        </div>
                      ) : (
                        ''
                      )
                    }
                  >
                    <span
                      css={{
                        color:
                          dailyCompliance.value === 100
                            ? colors.green
                            : colors.red,
                      }}
                    >{`${dailyCompliance.value}%`}</span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          <Select
            className={`export-button ${
              !isReadyForExport && 'export-button-disabled'
            }`}
            open={isSelectOpen}
            onClose={onSelectClose}
            onOpen={onSelectOpen}
            renderValue={() =>
              isReadyForExport && !isDownloading ? 'Export' : 'Loading...'
            }
            onClick={(event) => onSelectClick(event)}
            onChange={(event) => onSelectChange(event)}
            value={selectValue}
            disabled={!isReadyForExport || isDownloading}
            IconComponent={(props) => {
              return (
                <button
                  type="button"
                  {...props}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ChevronIcon
                    height={12}
                    width={14}
                    css={{
                      transform: 'rotate(90deg)',
                      color: !isReadyForExport
                        ? colors.dividerGrey
                        : colors.blue2,
                    }}
                  />
                </button>
              );
            }}
          >
            <MenuItem
              value={SelectValue.TopInsights}
              disabled={!digestData || digestData.length === 0}
            >
              Top Insights
            </MenuItem>
            {/* <MenuItem value={'allInsights'}>All Insights</MenuItem> */}
            <MenuItem value={SelectValue.AllSessionDetails}>
              All Session Details
            </MenuItem>
          </Select>
        </div>
        <div className="sessions-body">
          {selectedDate && (
            <SessionsDigest
              allSessionsByDate={sessionsByDateSelector}
              sessionsForSelectedDate={sessions}
              selectedDate={selectedDate}
              dailyCompliance={dailyCompliance?.value}
              dailyComplianceGaps={dailyComplianceGaps}
              actvitivitesExecutionsMap={activitiesExecutionsMap}
              lastTreatmentPlanOfDay={lastTreatmentPlanOfDay}
              setDigestData={setDigestData}
            />
          )}
          {sessions && (
            <SessionsView
              sessions={sessions}
              activitiesExecutionsMap={activitiesExecutionsMap}
            />
          )}
        </div>
      </div>
    </div>
  );
}
