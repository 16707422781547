/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

import Loader from '../commons/Loader';
import { fetchUsers } from '../../services/usersService';
import { notify } from '../../state-manager/slices/notificationsSlice';
import { useAppDispatch } from '../../state-manager/store';
import { createCase, fetchCases } from '../../services/casesService';
import CreateUserModal from '../forms/create-user-form/CreateUserModal';
import { PatientFormMode } from '../forms/create-user-form/create-patient-form/patientFormTypes';
import { setUsersAction } from '../../state-manager/slices/usersSlice';
import { setCasesAction } from '../../state-manager/slices/casesSlice';
import { urlParamsVulnerable } from '../../utils/urlSearchParamsUtils';

function notifyError(dispatch: Dispatch, message: string) {
  dispatch(
    notify({
      message,
      severity: 'error',
    }),
  );
  /* eslint-disable-next-line no-console */
  console.error(message);
}

function useResolvePatientId(
  emrPatientId: string | null,
  forceResolvePatientId: boolean,
) {
  const dispatch = useAppDispatch();

  const [patientId, setPatientId] = useState<string | null | undefined>();

  useEffect(() => {
    if (!emrPatientId) {
      notifyError(dispatch, 'please add emr-patient-id to url');
      return;
    }
    if (urlParamsVulnerable(emrPatientId)) {
      notifyError(dispatch, 'emr-patient-id contains invalid characters');
      return;
    }
    fetchUsers({ emrUserId: emrPatientId })
      .then((users) => {
        dispatch(setUsersAction({ users }));
        const patient = users[0];
        if (patient) {
          setPatientId(patient.user_id);
        } else {
          setPatientId(null);
        }
      })
      .catch(() => {
        notifyError(dispatch, 'failed to verify patient');
      });
  }, [emrPatientId, forceResolvePatientId]);

  return patientId;
}

function useResolveCaseId(
  emrCaseId: string | null,
  patientId: string | null | undefined,
) {
  const dispatch = useAppDispatch();

  const [caseId, setCaseId] = useState<string | undefined>();

  useEffect(() => {
    if (!patientId) {
      return;
    }
    if (!emrCaseId) {
      notifyError(dispatch, 'please add emr-case-id to url');
      return;
    }
    if (urlParamsVulnerable(emrCaseId)) {
      notifyError(dispatch, 'emr-case-id contains invalid characters');
      return;
    }
    fetchCases(patientId)
      .then((caseDtos) => {
        const caseDto = caseDtos?.find(({ case_id }) => case_id === emrCaseId);
        if (caseDto) {
          setCaseId(caseDto.case_id);
        } else {
          createCase(patientId, emrCaseId)
            .then((newCaseDto) => {
              setCaseId(newCaseDto.case_id);
              dispatch(
                setCasesAction({
                  cases: [...(caseDtos ?? []), newCaseDto],
                  patientId,
                }),
              );
            })
            .catch(() => {
              notifyError(dispatch, 'failed to create case');
              return;
            });
        }
      })
      .catch(() => {
        notifyError(dispatch, 'failed to get user cases');
      });
  }, [emrCaseId, patientId]);

  return caseId;
}

function useNavigateToPatientPage(
  patientId: string | null | undefined,
  caseId: string | undefined,
) {
  const history = useHistory();

  useEffect(() => {
    if (!caseId || !patientId) {
      return;
    }
    const searchParams = new URLSearchParams();
    searchParams.set('patient-id', patientId);
    searchParams.set('case-id', caseId);
    history.replace({
      pathname: '/patient',
      search: searchParams.toString(),
    });
  }, [caseId, patientId]);
}

export default function Emr() {
  const history = useHistory();
  const emrSearchParams = new URLSearchParams(history.location.search);
  const emrPatientId = emrSearchParams.get('emr-patient-id');
  const emrCaseId = emrSearchParams.get('emr-case-id');

  const [patientFormMode, setPatientFormMode] = useState<
    PatientFormMode | undefined
  >(undefined);

  const [forceResolvePatientId, setForceResolvePatientId] =
    useState<boolean>(false);

  const patientId = useResolvePatientId(emrPatientId, forceResolvePatientId);
  const caseId = useResolveCaseId(emrCaseId, patientId);
  useNavigateToPatientPage(patientId, caseId);

  useEffect(() => {
    if (patientId === null && emrPatientId !== null) {
      setPatientFormMode({
        mode: 'new',
        emrPatientId,
      });
    }
  }, [patientId, emrPatientId]);

  return (
    <>
      {!patientFormMode && <Loader />}
      <CreateUserModal
        type="patient"
        formMode={patientFormMode}
        setFormMode={setPatientFormMode}
        onCreateUser={() => {
          setForceResolvePatientId(true);
        }}
      />
    </>
  );
}
