export const colors = {
  black: '#000000',
  darkerGrey: '#707070',
  darkGrey: '#A8A8A8',
  dividerGrey: '#CACACA',
  mediumGrey: '#EDEDED',
  blue1: '#00285A',
  blue2: '#004A86',
  blue3: '#0068B5',
  blue35: '#0093dd',
  blue4: '#00A3F6',
  basicBlue: '#0054AE',
  white: '#FFFFFF',
  red: '#FF4E4E',
  green: '#22AD00',
  backgroundGrey: '#DFEBF1',
  purple: '#A852D0',
  lightGrey: '#F8F9FA',
  backgroundBlue: '#0068B580',
  orange: '#FF9900',
  backgroundDarkerGrey: '#F9F9F9',
  inactiveGrey: '#6A6D75',
  graph: {
    mediumPurple: '#BD00BD',
    coralPink: '#FF5662',
    darkYellow: '#A98F0F',
    viridianGreen: '#009999',
    darkOrange: '#B24501',
    orange: '#E96115',
    oliveGreen: '#515A3D',
    rackleyBlue: '#548FAD',
    pink: '#E83080',
    darkPurple: '#5B0D81',
  },
};
