import { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { useAppDispatch } from '../state-manager/store';
import { prepareMainUserAction } from '../state-manager/thunks/usersThunks';
import { useSelector } from 'react-redux';
import {
  getUserIdSelector,
  getRequestStatusSelector,
} from '../state-manager/selectors/appSelectors';
import { isFailed } from '../state-manager/utils/requestStatus';
import { notify } from '../state-manager/slices/notificationsSlice';
import { LoginService } from '../services/loginService';

export function useOnAuthenticatorChange() {
  const dispatch = useAppDispatch();
  const { user: amplifyUser } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (!amplifyUser) {
      return;
    }
    const loginService = LoginService.getInstance();
    loginService.setAmplifyUser(amplifyUser);
    const userId = loginService.getUserId();
    const role = loginService.getUserRole();
    if (!userId) {
      throw new Error('missing userId');
    }
    if (!role) {
      throw new Error('missing role');
    }
    dispatch(prepareMainUserAction(userId, role));
  }, [amplifyUser]);
}

export function useHandlePrepareMainUserFailed() {
  const dispatch = useAppDispatch();
  const userId = useSelector(getUserIdSelector);
  const requestStatus = useSelector(getRequestStatusSelector('user'));

  useEffect(() => {
    if (!userId) {
      return;
    }
    if (isFailed({ requestStatus, id: userId })) {
      dispatch(
        notify({
          message: 'Oops something went wrong - please contact customers service',
          severity: 'error',
          origin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }),
      );
    }
  }, [requestStatus, userId]);
}
