/** @jsxImportSource @emotion/react */

import { useEffect, useState, useRef } from 'react';

type CountdownTimerProps = {
  timeInSeconds: number;
  onTimerEnd: () => void;
};

export default function CountdownTimer({
  timeInSeconds,
  onTimerEnd,
}: CountdownTimerProps) {
  const [remainingTime, setRemainingTime] = useState<number>(timeInSeconds);
  const startTimeRef = useRef<number>(performance.now());
  const requestRef = useRef<number | null>(null);

  useEffect(() => {
    const updateTimer = () => {
      const elapsedTime = (performance.now() - startTimeRef.current) / 1000;
      const newRemainingTime = timeInSeconds - Math.floor(elapsedTime);

      if (newRemainingTime <= 0) {
        onTimerEnd();
      } else {
        setRemainingTime(newRemainingTime);
        requestRef.current = requestAnimationFrame(updateTimer);
      }
    };

    requestRef.current = requestAnimationFrame(updateTimer);

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [timeInSeconds, onTimerEnd]);

  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds =
    remainingTime % 60 == 0 && timeInSeconds <= 60
      ? timeInSeconds
      : remainingTime % 60 < 10
      ? `0${remainingTime % 60}`
      : remainingTime % 60;

  return (
    <div>
      <span>{timeInSeconds > 60 ? `${minutes}:${seconds}` : `${seconds}`}</span>
    </div>
  );
}
