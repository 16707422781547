/** @jsxImportSource @emotion/react */

import { CSSObject } from '@emotion/react';

import { colors } from '../../../../style/colors';
import Loader from '../../../commons/Loader';
import {
  genderOptions,
  ethnicityOptions,
  PatientFormData,
  medicalConditionOptions,
  PatientFormMode,
  surgicalLegOptions,
} from './patientFormTypes';
import {
  InputField,
  SelectInputField,
} from '../../../commons/form/forStateFrom';
import DisplayField from '../../../commons/form/DisplayField';
import { buttons } from '../../../../style/buttons';
import {
  card,
  cardBodyCss,
  cardFooterCss,
  cardHeaderCss,
} from '../../../../style/shapes';
import { FormErrors, OnChange } from '../../../commons/form/useForm';
import CloseIcon from '../../../../assets/close.svg';
import { CasesInFormView } from './CasesInFormView';
import { PatientPrivacySetting } from '../../../../types/backendType';
import PatientPrivacySettings from './PatientPrivacySettings';
import { toDatePickerFormat } from '../../../../utils/dateUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMeasurementSystemSelector } from '../../../../state-manager/selectors/appSelectors';
import { MeasurementSystems } from '../../../../types/appTypes';
import { ImperialUnits } from '../../../../utils/unitUtils';
import { isDisabled, UserTypes } from '../CreateUserUtils';

const formCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export default function CreatePatientFormView({
  onSubmit,
  onCancel,
  formErrors,
  loading,
  disabled,
  showFooter,
  title,
  onChange,
  formData,
  mode,
  patientPrivacySettings,
  handlePrivacySettingsChange,
  isUpdatingPatientPrivacySettings,
  emrPatientId,
}: {
  formData: PatientFormData;
  onChange: OnChange<PatientFormData>;
  onSubmit: () => void;
  onCancel?: () => void;
  formErrors: FormErrors<PatientFormData> | undefined;
  loading: boolean;
  disabled: boolean;
  showFooter: boolean;
  title: string;
  mode: PatientFormMode;
  patientPrivacySettings: PatientPrivacySetting[] | undefined;
  handlePrivacySettingsChange: (documentId: string) => void;
  isUpdatingPatientPrivacySettings: boolean;
  emrPatientId?: string;
}) {
  const { t } = useTranslation();
  const measurementSystem = useSelector(getMeasurementSystemSelector);
  const shared = { disabled, formErrors, formData, onChange };

  const isTestUser = formData.isTestUser;
  const maxDateOfBirth = new Date();
  const date120YearsAgo = new Date(
    new Date().setFullYear(new Date().getFullYear() - 120),
  );
  const minDateOfBirth = date120YearsAgo.toISOString().split('T')[0];
  const shouldShowPrivacySettings =
    !isTestUser && (mode.mode === 'edit' || mode.mode === 'preview');

  if (shouldShowPrivacySettings && !patientPrivacySettings) {
    return <Loader />;
  }

  return (
    <div
      css={{
        ...card,
        '.loader-background': { backgroundColor: `${colors.dividerGrey}80` },
      }}
    >
      <form css={formCss} noValidate aria-disabled autoComplete="off">
        <div css={cardHeaderCss}>
          {title}
          {onCancel && (
            <button type="button" onClick={onCancel}>
              <CloseIcon width={13} />
            </button>
          )}
        </div>
        <div css={cardBodyCss}>
          {emrPatientId && (
            <DisplayField value={emrPatientId} label={'Emr patient id'} />
          )}
          <InputField
            {...shared}
            name="firstName"
            label={isTestUser ? 'Name' : 'First Name'}
            required
            autoComplete="new-password"
            disabled={isDisabled(
              UserTypes.Patient,
              'firstName',
              disabled,
              mode.mode,
            )}
          />
          <InputField
            {...shared}
            name="middleName"
            label={'Middle Name'}
            autoComplete="new-password"
            disabled={isDisabled(
              UserTypes.Patient,
              'middleName',
              disabled,
              mode.mode,
            )}
          />
          {!isTestUser && (
            <InputField
              {...shared}
              name="lastName"
              label="Last Name"
              autoComplete="new-password"
              required
              disabled={isDisabled(
                UserTypes.Patient,
                'lastName',
                disabled,
                mode.mode,
              )}
            />
          )}
          {emrPatientId && (
            <CasesInFormView mode={mode} patientId={formData.id} />
          )}
          <InputField
            {...shared}
            name="dateOfBirth"
            label="Date of Birth"
            type="date"
            disabled={isDisabled(
              UserTypes.Patient,
              'dateOfBirth',
              disabled,
              mode.mode,
            )}
            max={toDatePickerFormat(maxDateOfBirth)}
            min={minDateOfBirth}
            absoluteDate={true}
          />
          {mode.mode !== 'newTestUser' && (
            <InputField
              {...shared}
              name="email"
              label="Email"
              required
              autoComplete="new-password"
              disabled={isDisabled(
                UserTypes.Patient,
                'email',
                disabled,
                mode.mode,
              )}
            />
          )}
          <InputField
            {...shared}
            name="height"
            label={
              measurementSystem === MeasurementSystems.Metric
                ? t('patientHeightMetric')
                : t('patientHeightImperial')
            }
            type="number"
            required
            disabled={isDisabled(
              UserTypes.Patient,
              'height',
              disabled,
              mode.mode,
            )}
            unit={ImperialUnits.Inch}
          />
          <DisplayField
            label="Registration Date"
            value={formData.creationDate.toLocaleDateString()}
          />
          {isTestUser && (
            <>
              <InputField
                {...shared}
                name="weight"
                label={
                  measurementSystem === MeasurementSystems.Metric
                    ? t('patientWeightMetric')
                    : t('patientWeightImplerial')
                }
                type="number"
                required
                disabled={isDisabled(
                  UserTypes.Patient,
                  'weight',
                  disabled,
                  mode.mode,
                )}
                unit={ImperialUnits.Pound}
              />
              <InputField
                {...shared}
                name="surgeryDate"
                label="Surgery Date"
                type="date"
                disabled={isDisabled(
                  UserTypes.Patient,
                  'surgeryDate',
                  disabled,
                  mode.mode,
                )}
              />
              <SelectInputField
                {...shared}
                name="medicalCondition"
                label="Medical Condition"
                options={medicalConditionOptions}
                required
                disabled={isDisabled(
                  UserTypes.Patient,
                  'medicalCondition',
                  disabled,
                  mode.mode,
                )}
              />
              <SelectInputField
                {...shared}
                name="surgicalLeg"
                label="Wounded Leg"
                options={surgicalLegOptions}
                required
                disabled={isDisabled(
                  UserTypes.Patient,
                  'surgicalLeg',
                  disabled,
                  mode.mode,
                )}
              />
              <SelectInputField
                {...shared}
                name="gender"
                label="Gender"
                options={genderOptions}
                required
                disabled={isDisabled(
                  UserTypes.Patient,
                  'gender',
                  disabled,
                  mode.mode,
                )}
              />
              <SelectInputField
                {...shared}
                name="ethnicity"
                label="Ethnicity"
                options={ethnicityOptions}
                required
                disabled={isDisabled(
                  UserTypes.Patient,
                  'ethnicity',
                  disabled,
                  mode.mode,
                )}
              />
            </>
          )}
          {shouldShowPrivacySettings && patientPrivacySettings && (
            <PatientPrivacySettings
              patientPrivacySettings={patientPrivacySettings}
              handlePrivacySettingsChange={handlePrivacySettingsChange}
              mode={mode}
              isUpdatingPatientPrivacySettings={
                isUpdatingPatientPrivacySettings
              }
            />
          )}
        </div>
        {showFooter && (
          <div css={cardFooterCss}>
            {onCancel && (
              <button
                css={{ ...buttons.secondary }}
                type="button"
                onClick={onCancel}
              >
                Cancel
              </button>
            )}
            <button
              css={{ ...buttons.primary }}
              type="button"
              onClick={onSubmit}
            >
              Save
            </button>
          </div>
        )}
      </form>
      {loading && <Loader />}
    </div>
  );
}
