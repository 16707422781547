import { isEmpty } from '../state-manager/utils/compare';
import { MeasurementSystems } from '../types/appTypes';

export function getDisplayUnit(unit: string | undefined) {
  if (unit === 'deg') {
    return '°';
  }
  return unit;
}

export function getUnitAsDisplayText(unit: string | undefined) {
  if (unit === 'deg') {
    return 'degrees';
  }
  if (unit === '%') {
    return 'percent';
  }
  if (unit === '#') {
    return '';
  }
  return unit;
}

export function getMetricMathDisplayText(math: string | undefined) {
  if (math == 'min') {
    return 'minimum';
  } else if (math == 'max') {
    return 'maximum';
  } else {
    return math;
  }
}
export enum MetricUnits {
  Kilogram = 'kg',
  Centimeter = 'cm',
  Meter = 'm',
  KilometerPerHour = 'km/hour',
}
export enum ImperialUnits {
  Pound = 'lb',
  Inch = 'in',
  Foot = 'ft',
  MilePerHour = 'mph',
}

export function isMetricUnit(unit: string | undefined): unit is MetricUnits {
  if (!unit) {
    return false;
  }
  return Object.values(MetricUnits).includes(unit as MetricUnits);
}

export function isImperialUnit(unit: string | undefined): unit is ImperialUnits {
  if (!unit) {
    return false;
  }
  return Object.values(ImperialUnits).includes(unit as ImperialUnits);
}

export function convertInchToCm(value: number) {
  return Number((value * 2.54).toPrecision(2));
}

export function convertCmToInch(value: number) {
  return Number((value * (1 / 2.54)).toPrecision(2));
}

export function convertLbToKg(value: number) {
  return Number((value * 0.45359).toPrecision(2));
}

export function convertKgToLb(value: number) {
  return Number((value * 2.20462).toPrecision(2));
}

export function convertMphToKmph(value: number) {
  return Number((value * 0.621371).toPrecision(2));
}
export function convertKmphToMph(value: number) {
  return Number((value * 1.60934).toPrecision(2));
}

export const imperialToMetricconversionFormulaMap: Record<
  ImperialUnits,
  (value: number) => number
> = {
  [ImperialUnits.Inch]: convertInchToCm,
  [ImperialUnits.Pound]: convertLbToKg,
  [ImperialUnits.Foot]: (value) => value,
  [ImperialUnits.MilePerHour]: convertMphToKmph,
};

export const metricToImperialConversionFormulaMap: Record<
  ImperialUnits,
  (value: number) => number
> = {
  [ImperialUnits.Inch]: convertCmToInch,
  [ImperialUnits.Pound]: convertKgToLb,
  [ImperialUnits.Foot]: (value) => value,
  [ImperialUnits.MilePerHour]: convertMphToKmph,
};

export function getEquivalentImperialUnit(unit: string | undefined) {
  const lowerCasedUnit = unit?.toLowerCase();
  if (isMetricUnit(lowerCasedUnit)) {
    const unitIndex = Object.values(MetricUnits).indexOf(lowerCasedUnit);
    return Object.values(ImperialUnits)[unitIndex];
  }
  return unit;
}

export function getUnitInputPropValue(unit: string | undefined, measurementSystem: string) {
  return unit && measurementSystem === MeasurementSystems.Metric
    ? undefined
    : getEquivalentImperialUnit(unit);
}

export function shouldUseDefaultUnit(unit: string | undefined, measurementSystem: string) {
  return (
    unit &&
    (measurementSystem === MeasurementSystems.Metric ||
      (isMetricUnit(unit) &&
        !Object.values(MetricUnits).includes(unit) &&
        isImperialUnit(unit) &&
        !Object.values(ImperialUnits).includes(unit)))
  );
}

export function getMeasurementSystemUnit(unit: string | undefined, measurementSystem: string) {
  return unit && !shouldUseDefaultUnit(unit, measurementSystem)
    ? getEquivalentImperialUnit(unit)
    : unit;
}

export function getMeasurementSystemValue(
  value: number | string | undefined | null,
  unit: string | undefined,
  measurementSystem: string,
): number | undefined {
  if (!isEmpty(value) && unit) {
    if (!shouldUseDefaultUnit(unit, measurementSystem) && isImperialUnit(unit)) {
      const metricToImperialConverter = metricToImperialConversionFormulaMap[unit];
      return metricToImperialConverter(Number(value));
    }
    return Number(value);
  }
}
