// Query parameters are not allowed to contain path traversal characters
export function urlParamsContainsPathChars(params: string | null | undefined): boolean {
  if (!params) {
    return false;
  }
  const invalidChars = ['/', '\\', '.'];
  return invalidChars.some((char) => params.includes(char));
}

// Check whether the URL parameters are vulnerable to path traversal attacks
export function urlParamsVulnerable(params: string | null | undefined): boolean {
  return urlParamsContainsPathChars(params);
}
