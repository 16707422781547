// Uppercases the first letter of a piece of text
export function capitalizeString(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatTimeFromSecToString(seconds: number) {
  const units = [
    { label: 'year', value: 60 * 60 * 24 * 365 },
    { label: 'day', value: 60 * 60 * 24 },
    { label: 'hour', value: 60 * 60 },
    { label: 'min', value: 60 },
    { label: 'sec', value: 1 },
  ];

  const timeParts: string[] = [];

  let remainingSeconds = seconds;
  for (let unit of units) {
    if (remainingSeconds >= unit.value) {
      const time = Math.floor(remainingSeconds / unit.value);
      remainingSeconds %= unit.value;
      timeParts.push(`${time} ${unit.label}`);
    }
  }

  if (timeParts.length === 0) {
    return '0 seconds';
  }

  // Format the result with commas and 'and' before the last part
  return timeParts.length > 1
    ? `${timeParts.slice(0, -1).join(', ')} and ${timeParts[timeParts.length - 1]}`
    : timeParts[0];
}
