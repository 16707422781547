import { css } from '@emotion/react';
import { fonts } from './fonts';
import { colors } from './colors';

export const signInCss = css`
  [data-amplify-container] {
    gap: 30px;
    border-radius: 1px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #cacaca;
    box-shadow: 4px 4px 10px 0px #00000040;
    width: 755px;
    height: 638px;
    display: flex;
    align-self: center;
  }
  ,
  [data-amplify-authenticator] [data-amplify-router] {
    border-width: 0;
    border-radius: 10px;
    border: 0;
    box-shadow: 0;
  }

  [data-amplify-authenticator] {
    border-width: 0;
    border-radius: 10px;
    border: 0;
    box-shadow: 0;
    height: 100vh;
  }
  ,
  [data-amplify-router] {
    border-width: 0;
    border: 0;
    box-shadow: 0;
    width: 100%;
    padding-right: 70px;
    padding-left: 70px;
  }
  ,
  [data-amplify-authenticator]: {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100vh;
  }
  ,
  [data-amplify-router-content] : {
    border: 0;
  }
  .amplify-button--primary {
    background: linear-gradient(338.15deg, #004a86 12.01%, #0068b5 83.38%);
  }
  ,
  [data-amplify-authenticator] {
    --amplify-components-authenticator-router-box-shadow: 0 0 0
      var(--amplify-colors-overlay-10);
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-form-padding: var(--amplify-space-medium)
      var(--amplify-space-xl) var(--amplify-space-xl);
    --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 0 var(--amplify-colors-purple-60);
    --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
    --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
    --amplify-components-button-link-color: var(--amplify-colors-purple-80);
  }
  ,
  [data-amplify-authenticator] [data-amplify-container] {
    width: 605px;
    min-height: 510px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
    input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, Edge, Opera */
}

.amplify-label{
...${fonts.largeLabel}
},
.amplify-button--link{
...${fonts.largeLabel}
color: ${colors.blue3}
},
.amplify-heading{
display:none
},
.amplify-button--primary.disabled {
  background-color: #d3d3d3; /* Light grey background */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  color: #a9a9a9; /* Light grey text */
}
`;
