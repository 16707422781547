/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import {
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { CSSObject } from '@emotion/react';

import { colors } from '../../style/colors';
import { ActivityExecution } from '../../models/ActivityExecution';
import { fonts } from '../../style/fonts';
import {
  getDisplayUnit,
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
} from '../../utils/unitUtils';
import { OverTimeResults } from '../../models/OverTimeResults';
import { QualitativeOverTimeResults } from '../../models/QualitativeOverTimeResults';

const scrollTooltipCss: CSSObject = {
  padding: '6px',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  maxWidth: '300px',
  maxHeight: '200px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

const metricResultsGraphCss: CSSObject = {
  position: 'relative',
  width: '100%',
  height: 'calc(100% - 42px);',
  '.recharts-default-legend': {
    marginLeft: '62px !important',
  },
  '.recharts-legend-item': {
    display: 'inline-flex !important',
    alignItems: 'center',
  },
  '.recharts-legend-item-text': {
    ...fonts.largeLabel,
    color: `${colors.black} !important`,
  },
  '.description': {
    marginLeft: '50px',
    ...fonts.text,
  },
};

function formatTime(seconds: number) {
  var date = new Date(0);
  date.setSeconds(seconds);
  var timeString = date.toLocaleTimeString([], {
    minute: 'numeric',
    second: '2-digit',
  });
  return timeString;
}

function formatYAxisValue(
  value: any,
  selectedMetrics: string[] | undefined,
  activityExecution: ActivityExecution | undefined,
  measurementSystem: string,
) {
  if (selectedMetrics?.length && selectedMetrics[0] && activityExecution) {
    const postMetric = activityExecution?.getMetric(selectedMetrics[0]);
    const measurementSystemUnit = getDisplayUnit(
      getMeasurementSystemUnit(postMetric?.unit, measurementSystem),
    );
    const measurementSystemValue = getMeasurementSystemValue(
      value,
      measurementSystemUnit,
      measurementSystem,
    )?.toFixed(0);

    return `${measurementSystemValue} ${measurementSystemUnit}`;
  }
  return '';
}

function CustomTooltip(
  props: TooltipProps<ValueType, NameType> & {
    extendedPoints: ExtendedPoint[];
    selectedMetrics: string[] | undefined;
    overTimeResults: OverTimeResults;
    qualitativeData?: QualitativeOverTimeResults;
    measurementSystem: string;
  },
) {
  const {
    active,
    payload,
    selectedMetrics,
    overTimeResults,
    extendedPoints,
    qualitativeData,
    measurementSystem,
  } = props;

  const data = payload && payload.length ? payload[0]?.payload : null;
  const pointIndex =
    data && data.qualitative !== null
      ? extendedPoints?.findIndex((point) => point.time === data?.time)
      : null;

  const qualitativeDetails = pointIndex
    ? qualitativeData?.getQualitativeDetails(pointIndex)
    : null;

  if (active && data) {
    return (
      <div className="area-chart-tooltip" css={scrollTooltipCss}>
        {selectedMetrics?.map((metricKey, index) => {
          const overTimeMetric = overTimeResults.getResultsByMetric(metricKey);
          if (overTimeMetric) {
            const metricName =
              overTimeResults.getFormattedMetricLabel(overTimeMetric);
            const measurementSystemUnit = getDisplayUnit(
              getMeasurementSystemUnit(overTimeMetric.unit, measurementSystem),
            );

            const measurementSystemValue = getMeasurementSystemValue(
              data[metricKey],
              measurementSystemUnit,
              measurementSystem,
            )?.toFixed(2);

            const measurementSystemStandardDeviation =
              getMeasurementSystemValue(
                overTimeMetric.standardDeviation,
                measurementSystemUnit,
                measurementSystem,
              );
            const metricStandardDeviation = overTimeMetric.standardDeviation
              ? `± ${measurementSystemStandardDeviation}${measurementSystemUnit}`
              : '';
            return (
              <div
                key={metricKey}
                css={{ color: Object.values(colors.graph)[index] }}
              >
                {`${metricName}: ${measurementSystemValue}${measurementSystemUnit} 
                  ${metricStandardDeviation}`}
              </div>
            );
          }
        })}

        {data &&
          data.qualitative &&
          qualitativeDetails?.map((qual) => (
            <div key={qual.message_id}>
              {'expression' in qual
                ? `${qualitativeData?.getQualitativeExpression(
                    qual,
                    overTimeResults,
                  )} value not between ${qual.lower_range} 
              and ${qual.upper_range} for at least ${
                    qual.violation_time
                  } milliseconds.`
                : qual.violation}
            </div>
          ))}

        <div>Time: {Number(data.time).toFixed(2)} sec</div>
      </div>
    );
  }

  return null;
}

// TODO: Uncomment if manual tick calculation is needed
// function getTicks(metricResults: ExtendedPoint[]) {
//   const wantedNumberOfTicks = 5;

//   const ticks: number[] = [];
//   metricResults.forEach(({ time }) => {
//     if (ticks.length === 0) {
//       if (time >= 0.5) {
//         ticks.push(time);
//       }
//     } else {
//       const lastTick = ticks[ticks.length - 1];
//       if (lastTick !== undefined && time >= lastTick + 0.5) {
//         ticks.push(time);
//       }
//     }
//   });

//   if (ticks.length <= wantedNumberOfTicks) {
//     return ticks;
//   }

//   const factor = Math.round(ticks.length / wantedNumberOfTicks);
//   const finalTicks = ticks.filter((_, index) => {
//     return index % factor === 0;
//   });
//   return finalTicks;
// }

type MetricKey = string;
type MetricValue = number;

export type ExtendedPoint = {
  time: number;
} & Record<MetricKey, MetricValue>;

const initDomainX = {
  left: 'dataMin',
  right: 'dataMax',
};

export default function MetricResultsGraph({
  extendedPoints,
  selectedMetrics,
  onGraphClick,
  cursorPosition,
  setCursorPosition,
  activityExecution,
  overTimeResults,
  qualitativeData,
  measurementSystem,
}: {
  extendedPoints: ExtendedPoint[];
  selectedMetrics: string[] | undefined;
  onGraphClick?: (point: ExtendedPoint) => void;
  cursorPosition?: number | undefined;
  setCursorPosition?: (cursorPosition: number | undefined) => void;
  activityExecution: ActivityExecution | undefined;
  overTimeResults: OverTimeResults;
  qualitativeData?: QualitativeOverTimeResults;
  measurementSystem: string;
}) {
  const [fixedCursorPosition, setFixedCursorPosition] = useState<
    number | undefined
  >();

  const [refAreaLeft, setRefAreaLeft] = useState<number | undefined>();

  const [refAreaRight, setRefAreaRight] = useState<number | undefined>();

  const [domainX, setDomainX] = useState<{
    left: string | number;
    right: string | number;
  }>(initDomainX);

  // const ticks = useMemo(() => {
  //   return getTicks(extendedPoints);
  // }, [extendedPoints]);

  const findNearestPoint = (x: number) => {
    return extendedPoints.find(({ time }) => time >= x);
  };

  useEffect(() => {
    if (cursorPosition === undefined) {
      return;
    }
    const fixed = findNearestPoint(cursorPosition);
    if (fixed !== undefined) {
      setFixedCursorPosition(fixed.time);
    }
  }, [cursorPosition]);

  const zoom = () => {
    if (
      refAreaLeft === refAreaRight ||
      refAreaRight === undefined ||
      refAreaLeft === undefined
    ) {
      setRefAreaLeft(undefined);
      setRefAreaRight(undefined);
      return;
    }

    let left = refAreaLeft;
    let right = refAreaRight;

    if (refAreaLeft > refAreaRight) {
      left = refAreaRight;
      right = refAreaLeft;
    }

    setRefAreaLeft(undefined);
    setRefAreaRight(undefined);
    setDomainX({ left, right });
  };

  return (
    <div css={metricResultsGraphCss}>
      <ResponsiveContainer>
        <LineChart
          data={extendedPoints}
          onMouseDown={(chartState: CategoricalChartState | null) => {
            const payload = chartState?.activePayload?.[0].payload;
            if (payload) {
              onGraphClick?.(payload);
              setCursorPosition?.(payload.time);
            }
            setRefAreaLeft(Number(chartState?.activeLabel));
          }}
          onMouseMove={(chartState: CategoricalChartState | null) => {
            if (refAreaLeft) {
              setRefAreaRight(Number(chartState?.activeLabel));
            }
          }}
          onMouseUp={() => {
            zoom();
          }}
        >
          <YAxis
            tickFormatter={(value) => {
              return formatYAxisValue(
                value,
                selectedMetrics,
                activityExecution,
                measurementSystem,
              );
            }}
            domain={['dataMin', 'dataMax']}
          />
          <XAxis
            type="number"
            allowDataOverflow
            dataKey="time"
            tickCount={6}
            tickFormatter={(seconds) => {
              return formatTime(seconds);
            }}
            domain={[domainX.left, domainX.right]}
          />
          {selectedMetrics?.map((metricKey, index) => (
            <Line
              key={metricKey}
              type="monotone"
              dataKey={metricKey}
              stroke={Object.values(colors.graph)[index]}
              dot={false}
            />
          ))}
          <Line
            key={'qualitative'}
            type="monotone"
            dataKey={'qualitative'}
            stroke={'red'}
            dot={false}
            strokeWidth={4}
          />
          <Tooltip
            cursor
            active={true}
            content={
              <CustomTooltip
                extendedPoints={extendedPoints}
                selectedMetrics={selectedMetrics}
                overTimeResults={overTimeResults}
                qualitativeData={qualitativeData}
                measurementSystem={measurementSystem}
              />
            }
          />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            wrapperStyle={{
              paddingBottom: 20,
            }}
            formatter={(value) => {
              const overTimeMetric = overTimeResults.getResultsByMetric(value);
              if (overTimeMetric) {
                return overTimeResults.getFormattedMetricLabel(overTimeMetric);
              }
              if (value == 'qualitative') {
                return 'Qualitative';
              }
            }}
            iconType="square"
            iconSize={15}
            align="left"
          />
          {fixedCursorPosition && (
            <ReferenceLine x={fixedCursorPosition} stroke={colors.blue4} />
          )}
          {selectedMetrics?.map((metricKey, index) => {
            const goal = activityExecution?.getGoal(metricKey);
            if (goal !== undefined) {
              return (
                <ReferenceLine
                  key={metricKey}
                  y={goal}
                  stroke={Object.values(colors.graph)[index]}
                  strokeDasharray="3 3"
                />
              );
            }
          })}
          {refAreaLeft && refAreaRight && (
            <ReferenceArea
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      <div className="description">
        {`Move to specific areas of the graph to see the corresponding \
video by clicking on the chart.
Click anywhere in the graph and drag the mouse to zoom in on a particular section of the graph.`}
      </div>
      {domainX !== initDomainX && (
        <button
          type="button"
          css={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => {
            setDomainX(initDomainX);
          }}
        >
          <ZoomOutIcon />
        </button>
      )}
    </div>
  );
}
