import { Expression, QualitativeOptional, DaaOptionalData } from '../types/backendType';
import { OverTimeResults } from './OverTimeResults';

export class QualitativeOverTimeResults {
  private qualitativeOverTimeResults;

  private qualitativeMetaData;

  private daaOptionalList;

  constructor(
    qualitativeOverTimeResults: (number[] | null)[],
    qualitativeMetaData: QualitativeOptional[],
    daaOptionalList?: DaaOptionalData[],
  ) {
    this.qualitativeOverTimeResults = qualitativeOverTimeResults;
    this.qualitativeMetaData = qualitativeMetaData;
    this.daaOptionalList = daaOptionalList;
  }

  get allQualitativeMetaData() {
    return this.qualitativeMetaData;
  }

  get allQualitativeResults() {
    return this.qualitativeOverTimeResults;
  }

  getQualitativeExpression(
    qualitativeData: QualitativeOptional,
    overTimeResults: OverTimeResults,
  ) {
    let expression = '';
    qualitativeData.expression.forEach((exp: Expression) => {
      let text = '';
      if ('metric' in exp) {
        let result = overTimeResults.getResultsByMetric(exp.metric);
        let displayName = result
          ? overTimeResults.getFormattedMetricLabel(result)
          : exp.metric.replace('_', ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        text = displayName;
      } else {
        text = exp.operator;
      }
      expression += text;
    });

    return expression;
  }

  getQualitativeDetails(index: number) {
    const qualitativeResult = new Set(this.qualitativeOverTimeResults[index]);
    let details: (QualitativeOptional | DaaOptionalData)[] = [];
    qualitativeResult?.forEach((value: number) => {
      const detailsValue = this.qualitativeMetaData[value];
      if (value >= 0 && detailsValue) {
        details.push(detailsValue);
      } else if (value < 0) {
        let daaValue = this.daaOptionalList?.find((item) => item.index == value);
        if (daaValue) {
          details.push(daaValue);
        }
      }
    });
    return details;
  }
}
