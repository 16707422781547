/** @jsxImportSource @emotion/react */

import {
  Document as PdfDocument,
  Page,
  Text as PdfText,
  View,
  StyleSheet as PdfStyleSheet,
  Image as PdfImage,
  Font,
} from '@react-pdf/renderer';
import PdfLogo from '../../../assets/pdfLogo.png';
import { colors } from '../../../style/colors';
import { shapes } from '../../../style/shapes';
import { format } from 'date-fns';
import {
  getLegacyPartiallyCompletedText,
  getMovementResultsArray,
  getShouldShowLegacyPartiallyCompleted,
  getShouldShowPartiallyCompleted,
} from './SessionsUntils';
import PdfHeaderLogo from '../../../assets/pdfLogo2.png';
import { isEmpty } from '../../../state-manager/utils/compare';
import {
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
  getUnitAsDisplayText,
} from '../../../utils/unitUtils';
import { ActivityExecution, Result } from '../../../models/ActivityExecution';
import { MeasurementSystems } from '../../../types/appTypes';
import { EquipmentInput } from '../treatment-plans/creation/treatmentPlanTypes';
import { Session } from '../../../models/Session';
import { capitalizeString } from '../../../utils/stringUtils';
import { isLoadInput } from '../treatment-plans/TreatmentPlansUtils';

Font.register({
  family: 'IntelOne Text',
  fonts: [
    { src: '/fonts/intelone-text-regular.woff' },
    { src: '/fonts/intelone-text-medium.woff' },
  ],
});

const styles = PdfStyleSheet.create({
  page: {
    padding: '77px 77px',
    fontFamily: 'IntelOne Text',
    fontSize: '12px',
    fontWeight: 400,
  },
  logo: {
    width: '112px',
    height: '49px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '14px',
  },
  generalInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: shapes.border,
    backgroundColor: colors.backgroundDarkerGrey,
    padding: '14px',
    marginBottom: '20px',
  },
  generalInfoSubcontainer: {
    display: 'flex',
    gap: '15px',
  },
  generalInfoHeader: {
    fontWeight: 500,
    color: colors.blue2,
  },
  sessionContainer: {
    display: 'flex',
    gap: '10px',
    borderBottom: shapes.border,
    paddingBottom: '20px',
  },
  sessionTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: colors.blue3,
  },
  activityTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activityName: {
    fontSize: '14px',
    fontWeight: 500,
  },
  resultsTable: {
    display: 'flex',
    flexDirection: 'column',
    border: `0.5px solid ${colors.dividerGrey}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  columnCell: {
    height: '27px',
    padding: '5px 10px',
    border: `0.5px solid ${colors.dividerGrey}`,
  },

  partiallyCompleted: {
    border: `0.5px solid ${colors.dividerGrey}`,
    borderLeft: 'none',
    width: '203px',
  },
  partiallyCompletedHeader: {
    borderBottom: `0.5px solid ${colors.dividerGrey}`,
    padding: '5px 10px',
    fontWeight: 500,
    height: '27px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.darkGrey,
    position: 'absolute',
    top: '40px',
    right: '77px',
    left: '77px',
    fontSize: '10px',
  },
  headerLogo: {
    width: '88px',
    height: '16px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.darkGrey,
    position: 'absolute',
    bottom: '40px',
    right: '77px',
    left: '77px',
    fontSize: '10px',
  },
  metricsColumnCell: {
    height: '40px',
    border: `0.5px solid ${colors.dividerGrey}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1px',
  },
  equipmentColumnCell: {
    width: '100px',
    padding: '5px 10px',
    height: '30px',
    border: `0.5px solid ${colors.dividerGrey}`,
  },
});

type SessionsPdfProps = {
  patientName: string;
  caseId: string;
  sessions: Session[];
  duration: number;
  compliance: {
    value: number;
    activitiesWithoutExecution: ActivityExecution[] | undefined;
  };
  clinicianName: string;
  measurementSystem: MeasurementSystems;
};

function getColor(goal: number | undefined, value: number | undefined) {
  if (!value || !goal) {
    return colors.black;
  }
  if (value === goal) {
    return colors.green;
  }
  return colors.red;
}

function Footer({ clinicianName }: { clinicianName: string | undefined }) {
  const currentDate = format(new Date(), 'MM/dd/yyy');

  return (
    <View fixed style={styles.footer}>
      <PdfText>
        Exported by {clinicianName} on {currentDate}
      </PdfText>
      <PdfText
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
      />
    </View>
  );
}

function ResultsTable({
  activityExecution,
  shouldShowLegacyPartiallyCompleted,
  shouldShowPartiallyCompleted,
}: {
  activityExecution: ActivityExecution;
  shouldShowLegacyPartiallyCompleted: boolean;
  shouldShowPartiallyCompleted: boolean;
}) {
  return (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={{ ...styles.resultsTable, width: '242px' }}>
        <View style={styles.row}>
          <PdfText style={{ ...styles.columnCell, width: '110px' }}></PdfText>
          <PdfText
            style={{ ...styles.columnCell, width: '64px', fontWeight: 500 }}
          >
            Goal
          </PdfText>
          <PdfText
            style={{ ...styles.columnCell, width: '73px', fontWeight: 500 }}
          >
            Actual
          </PdfText>
        </View>

        <View style={styles.row}>
          <PdfText
            style={{ ...styles.columnCell, width: '110px', fontWeight: 500 }}
          >
            Sets
          </PdfText>
          <PdfText style={{ ...styles.columnCell, width: '64px' }}>
            {activityExecution.activity?.sets}
          </PdfText>
          <PdfText
            style={{
              ...styles.columnCell,
              width: '73px',
              color: getColor(
                activityExecution.activity?.sets,
                activityExecution.sets,
              ),
            }}
          >
            {activityExecution.sets ?? 0}
          </PdfText>
        </View>

        {!isEmpty(activityExecution.activity?.reps) && (
          <View style={styles.row}>
            <PdfText
              style={{ ...styles.columnCell, width: '110px', fontWeight: 500 }}
            >
              Reps
            </PdfText>
            <PdfText style={{ ...styles.columnCell, width: '64px' }}>
              {activityExecution.activity?.reps}
            </PdfText>
            <PdfText style={{ ...styles.columnCell, width: '73px' }}>
              {getMovementResultsArray(
                activityExecution.activity?.sets,
                activityExecution.reps,
              )?.map((repsPerSet: number, index: number) => {
                return (
                  <PdfText
                    key={index}
                    style={{
                      color: getColor(
                        activityExecution.activity?.reps,
                        repsPerSet,
                      ),
                    }}
                  >
                    {`${repsPerSet}${
                      activityExecution.reps &&
                      index < activityExecution.reps.length - 1
                        ? ', '
                        : ''
                    }`}
                  </PdfText>
                );
              })}
            </PdfText>
          </View>
        )}

        {!isEmpty(activityExecution.activity?.hold) && (
          <View style={styles.row}>
            <PdfText
              style={{ ...styles.columnCell, width: '110px', fontWeight: 500 }}
            >
              Hold(seconds)
            </PdfText>
            <PdfText style={{ ...styles.columnCell, width: '64px' }}>
              {activityExecution.activity?.hold}
            </PdfText>
            {/* TODO: Actual Hold  */}
            <PdfText style={{ ...styles.columnCell, width: '73px' }}></PdfText>
          </View>
        )}

        {!isEmpty(activityExecution.activity?.duration) && (
          <View style={styles.row}>
            <PdfText
              style={{ ...styles.columnCell, width: '110px', fontWeight: 500 }}
            >
              Duration
            </PdfText>
            <PdfText style={{ ...styles.columnCell, width: '64px' }}>
              {activityExecution.activity?.duration}
            </PdfText>
            <PdfText style={{ ...styles.columnCell, width: '73px' }}>
              {getMovementResultsArray(
                activityExecution.activity?.sets,
                activityExecution.duration,
              )?.map((durationPerSet: number, index: number) => {
                return (
                  <PdfText
                    key={index}
                    style={{
                      color: getColor(
                        activityExecution.activity?.duration,
                        durationPerSet,
                      ),
                    }}
                  >
                    {`${durationPerSet.toFixed(0)}${
                      activityExecution.duration &&
                      index < activityExecution.duration.length - 1
                        ? ', '
                        : ''
                    }`}
                  </PdfText>
                );
              })}
            </PdfText>
          </View>
        )}
      </View>

      {(shouldShowLegacyPartiallyCompleted || shouldShowPartiallyCompleted) && (
        <View style={styles.partiallyCompleted}>
          <PdfText style={styles.partiallyCompletedHeader}>
            Partially Completed
          </PdfText>
          <PdfText style={{ padding: '5px 10px' }}>
            {/* Legacy Partially Completed column */}
            {shouldShowLegacyPartiallyCompleted && (
              <PdfText>
                {getLegacyPartiallyCompletedText(
                  activityExecution.statusDescription,
                )}
              </PdfText>
            )}

            {/*New Partially Completed Column */}
            {shouldShowPartiallyCompleted && (
              <PdfText>{activityExecution.statusDescription}</PdfText>
            )}
          </PdfText>
        </View>
      )}
    </View>
  );
}

function MetricResultsTable({
  activityResults,
  measurementSystem,
}: {
  activityResults: Result[];
  measurementSystem: MeasurementSystems;
}) {
  if (!activityResults.length) {
    return <View />;
  }

  return (
    <View style={{ ...styles.resultsTable, width: '450px' }}>
      <View style={styles.row}>
        <View style={{ ...styles.metricsColumnCell, width: '140px' }}></View>
        <View
          style={{
            ...styles.metricsColumnCell,
            width: '47px',
            fontWeight: 500,
          }}
        >
          <PdfText>Goal</PdfText>
        </View>
        <View
          style={{
            ...styles.metricsColumnCell,
            width: '60px',
            fontWeight: 500,
          }}
        >
          <PdfText>Actual</PdfText>
        </View>
        <View
          style={{
            ...styles.metricsColumnCell,
            width: '70px',
            fontWeight: 500,
          }}
        >
          <PdfText>Unit</PdfText>
        </View>
        <View
          style={{
            ...styles.metricsColumnCell,

            width: '58px',
            fontWeight: 500,
          }}
        >
          <PdfText>Error</PdfText>
          <PdfText>Range</PdfText>
        </View>
        <View
          style={{
            ...styles.metricsColumnCell,
            width: '78px',
            fontWeight: 500,
          }}
        >
          <PdfText> Measure</PdfText>
        </View>
      </View>
      {activityResults.map((metric, index) => {
        const measurementSystemUnit = getMeasurementSystemUnit(
          metric.unit,
          measurementSystem,
        );

        const metricSide = metric.side !== 'NA' ? metric.side : '';

        const measurementSystemValue = getMeasurementSystemValue(
          metric.value,
          measurementSystemUnit,
          measurementSystem,
        )?.toFixed(0);

        const measurementSystemGoal =
          getMeasurementSystemValue(
            metric.goal,
            measurementSystemUnit,
            measurementSystem,
          ) ?? 0;

        const measurementSystemStandardDeviation = getMeasurementSystemValue(
          metric.standardDeviation,
          measurementSystemUnit,
          measurementSystem,
        );
        return (
          <View key={index} style={styles.row}>
            <View
              style={{
                ...styles.metricsColumnCell,
                width: '140px',
                fontWeight: 500,
              }}
              wrap={true}
            >
              <PdfText>
                {metric.name} {metricSide && `(${metricSide})`}
              </PdfText>
            </View>
            <View style={{ ...styles.metricsColumnCell, width: '47px' }}>
              <PdfText>{measurementSystemGoal}</PdfText>
            </View>
            <View
              style={{
                ...styles.metricsColumnCell,
                width: '60px',
                color: getColor(metric.goal, metric.value),
              }}
            >
              <PdfText>{measurementSystemValue}</PdfText>
            </View>
            <View style={{ ...styles.metricsColumnCell, width: '70px' }}>
              <PdfText>
                {capitalizeString(
                  getUnitAsDisplayText(measurementSystemUnit) ?? '',
                )}
              </PdfText>
            </View>
            <View style={{ ...styles.metricsColumnCell, width: '58px' }}>
              <PdfText>±{measurementSystemStandardDeviation}</PdfText>
            </View>
            <View style={{ ...styles.metricsColumnCell, width: '78px' }}>
              <PdfText>{capitalizeString(metric.math ?? '')}</PdfText>
            </View>
          </View>
        );
      })}
    </View>
  );
}

function EquipmentTable({
  activityEquipment,
  measurementSystem,
}: {
  activityEquipment: EquipmentInput[];
  measurementSystem: MeasurementSystems;
}) {
  if (!activityEquipment.length) {
    return <View />;
  }

  return (
    <View style={{ ...styles.resultsTable, width: '200px' }}>
      <View style={styles.row}>
        <PdfText style={{ ...styles.equipmentColumnCell, fontWeight: 500 }}>
          Equipment
        </PdfText>
        <PdfText style={{ ...styles.equipmentColumnCell, fontWeight: 500 }}>
          Details
        </PdfText>
      </View>
      {activityEquipment.map((device, index) => {
        const measurementSystemUnit = getMeasurementSystemUnit(
          device.Units,
          measurementSystem,
        );

        const value = isLoadInput(device)
          ? getMeasurementSystemValue(
              device.Value,
              measurementSystemUnit,
              measurementSystem,
            )?.toFixed(0)
          : device.Value;

        return (
          <View key={index} style={styles.row}>
            <PdfText style={{ ...styles.equipmentColumnCell, fontWeight: 500 }}>
              {device.Name}
            </PdfText>
            <PdfText style={styles.equipmentColumnCell}>
              {`${value || ''} ${
                measurementSystemUnit?.toLocaleLowerCase() ?? ''
              }`}
            </PdfText>
          </View>
        );
      })}
    </View>
  );
}

export default function SessionsPDF({
  patientName,
  caseId,
  sessions,
  duration,
  compliance,
  clinicianName,
  measurementSystem,
}: SessionsPdfProps) {
  const formattedDate =
    sessions[0] && format(sessions[0].startDate, 'MM/dd/yyy');

  return (
    <PdfDocument>
      <Page style={styles.page}>
        <View
          style={styles.header}
          fixed
          render={({ pageNumber }) => {
            return (
              pageNumber !== 1 && (
                <>
                  <PdfImage src={PdfHeaderLogo} style={styles.headerLogo} />
                  <PdfText>Session {formattedDate}</PdfText>
                  <PdfText>
                    {patientName} ({caseId})
                  </PdfText>
                </>
              )
            );
          }}
        >
          <PdfImage src={PdfHeaderLogo} style={styles.headerLogo} />
          <PdfText>Session {formattedDate}</PdfText>
          <PdfText>
            {patientName} ({caseId})
          </PdfText>
        </View>

        <PdfImage src={PdfLogo} style={styles.logo} />
        <PdfText style={styles.title}>Sessions Results</PdfText>
        <View style={styles.generalInfoContainer}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '40px',
            }}
          >
            <View style={styles.generalInfoSubcontainer}>
              <View>
                <PdfText style={styles.generalInfoHeader}>Assigned To</PdfText>
                <PdfText style={{ fontWeight: 500, fontSize: '15px' }}>
                  {patientName}
                </PdfText>
              </View>
              <View>
                <PdfText style={styles.generalInfoHeader}>
                  Number of Sessions
                </PdfText>
                <PdfText>{sessions.length}</PdfText>
              </View>
            </View>

            <View style={styles.generalInfoSubcontainer}>
              <View>
                <PdfText style={styles.generalInfoHeader}>Case ID</PdfText>
                <PdfText>{caseId}</PdfText>
              </View>
              <View>
                <PdfText style={styles.generalInfoHeader}>Duration</PdfText>
                <PdfText>{duration}</PdfText>
              </View>
            </View>

            <View style={styles.generalInfoSubcontainer}>
              <View>
                <PdfText style={styles.generalInfoHeader}>
                  Date Performed
                </PdfText>
                <PdfText>{formattedDate}</PdfText>
              </View>
              <View>
                <PdfText style={styles.generalInfoHeader}>Compliance</PdfText>
                <PdfText
                  style={{
                    color: compliance.value === 100 ? colors.green : colors.red,
                  }}
                >
                  {compliance.value}%
                </PdfText>
              </View>
            </View>
          </View>
        </View>

        {sessions.map((session, index) => {
          return (
            <View key={index} style={styles.sessionContainer}>
              <PdfText style={styles.sessionTitle}>{`Session ${
                index + 1
              }`}</PdfText>
              {session.activitiesExecutions.map(
                (activityExecution, executionIndex) => {
                  const shouldShowLegacyPartiallyCompleted =
                    getShouldShowLegacyPartiallyCompleted(
                      activityExecution.status,
                      activityExecution.statusDescription,
                    );

                  const shouldShowPartiallyCompleted =
                    getShouldShowPartiallyCompleted(
                      activityExecution.status,
                      activityExecution.activityDefinition?.automationLevel,
                    );

                  return (
                    <View
                      key={executionIndex}
                      style={{ display: 'flex', gap: '10px' }}
                    >
                      <View style={styles.activityTitleContainer}>
                        <PdfText style={styles.activityName}>
                          {executionIndex + 1}. {activityExecution.name}-
                        </PdfText>
                        <PdfText>
                          {activityExecution.compliance}% Compliance
                        </PdfText>
                      </View>

                      <ResultsTable
                        activityExecution={activityExecution}
                        shouldShowLegacyPartiallyCompleted={
                          shouldShowLegacyPartiallyCompleted
                        }
                        shouldShowPartiallyCompleted={
                          shouldShowPartiallyCompleted
                        }
                      />

                      <MetricResultsTable
                        activityResults={activityExecution.results}
                        measurementSystem={measurementSystem}
                      />

                      {activityExecution.activity?.equipment?.length && (
                        <EquipmentTable
                          activityEquipment={
                            activityExecution.activity.equipment
                          }
                          measurementSystem={measurementSystem}
                        />
                      )}
                    </View>
                  );
                },
              )}
            </View>
          );
        })}
        <Footer clinicianName={clinicianName} />
      </Page>
    </PdfDocument>
  );
}
